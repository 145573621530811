import '@babel/polyfill/noConflict';

import { isIE } from 'react-device-detect';

import { get, forEach } from 'lodash';

import AcctXfer from 'containers/Landing/AcctXfer/Loadable';
import HomeResi from 'containers/Home/Resi/Loadable';
import HomeComm from 'containers/Home/Comm/Loadable';
import HomeRedirect from 'containers/Home/Redirect';
import HomepageDEPREC from 'containers/Landing/HomepageDEPREC/Loadable';
import FiltrationEducation from 'containers/Landing/FiltrationEducation/Loadable';
import GetStarted from 'containers/Landing/GetStarted/Loadable';
import Support from 'containers/Landing/Support/Loadable';
import WhyPage from 'containers/Landing/WhyPage/Loadable';
import OurProducts from 'containers/Landing/OurProducts/Loadable';
import PrivacyPolicy from 'containers/Landing/PrivacyPolicy/Loadable';
import BridgeCostco from 'containers/Bridge/Costco';
import CostcowaterService from 'containers/Landing/CostcowaterService/Loadable';
import CostcowaterPrivacy from 'containers/Landing/CostcowaterPrivacy/Loadable';
import CostcowaterTerms from 'containers/Landing/CostcowaterTerms/Loadable';
import CostcowaterCCPA from 'containers/Landing/CostcowaterCCPA/Loadable';
import CostcowaterCookies from 'containers/Landing/CostcowaterCookies/Loadable';
import CostcoWaterCASupplyChain from 'containers/Landing/CostcoWaterCASupplyChain/Loadable';
import CookiePolicy from 'containers/Landing/CookiePolicy/Loadable';
import PrimoFAQ from 'containers/Landing/FAQ/primo/Loadable';
import PurefloFAQ from 'containers/Landing/FAQ/pureflo/Loadable';
import TermsOfUse from 'containers/Landing/TermsOfUse/Loadable';
import CANoticeAtCollection from 'containers/Landing/CANoticeAtCollection/Loadable';
import TexasNoticeAtCollection from 'containers/Landing/TexasNoticeAtCollection/Loadable';
import LeadGen from 'containers/Landing/LeadGen/Loadable';
import TermsAndConditions from 'containers/Landing/TermsAndConditions/Loadable';
import OurStory from 'containers/BrandStory/Loadable';
import CostcoWaterNonServ from 'containers/Landing/CostcoWaterNonServ/Loadable';
import CostcoWaterNonServAbsopure from 'containers/Landing/CostcoWaterNonServAbsopure/Loadable';
import CostcoWaterNonServPremiumwater from 'containers/Landing/CostcoWaterNonServPremiumwater/Loadable';
import CorporateResponsibilityDEPREC from 'containers/Landing/CorporateResponsibility/Loadable';
import Refer from 'containers/Landing/Refer/Loadable';
import Responsibility from 'containers/Landing/Responsibility/Loadable';
import WaterQuality from 'containers/Landing/WaterQuality/Loadable';
import PrimoAccount from 'containers/PrimoAccount/Loadable';
import BaseLayout from 'containers/Layout/BaseLayout';
import ContentfulPreview from 'containers/ContentfulPreview/Loadable';
import About from 'containers/Landing/About/Loadable';
import Cart from 'containers/Cart/Loadable';
import PrimoCheckoutPage from 'containers/PrimoCheckoutPage/Loadable';
import CaliforniaTransparencyAct from 'containers/Landing/CaliforniaTransparencyAct/Loadable';
import WhoWeServe from 'containers/Landing/WhoWeServe/Loadable';
import CoolerCleaningService from 'containers/Landing/CoolerCleaningService/Loadable';
import CoffeeService from 'containers/Landing/CoffeeService/Loadable';
import CoffeeEquipment from 'containers/Landing/CoffeeEquipment/Loadable';
import ServicesResi from 'containers/Services/Resi/Loadable';
import ServicesComm from 'containers/Services/Comm/Loadable';
import WaterEquipment from 'containers/Landing/WaterEquipment/Loadable';
import FiltrationService from 'containers/Landing/FiltrationService/Loadable';
import { pages as primoCheckoutPages } from 'containers/PrimoCheckoutPage/constants';
import authRoutes from 'containers/Authentication/routes';
import PrimoProfile from 'containers/PrimoProfile/Loadable';
import HomeOfficeProducts from 'containers/Showcase/Loadable';
import { ProductList } from 'containers/PrimoProducts/ProductListPage';
import {
  ProductPageLoggedIn,
  ProductPageLoggedOut,
} from 'containers/PrimoProducts/ProductPage';
import QuickShop from 'containers/QuickShop/Loadable';
import { pages as quickShopPages } from 'containers/QuickShop/constants';
import WaterStartOrder from 'containers/WaterStartOrder/Loadable';
import WaterNonServiceable from 'containers/WaterStartOrder/LoadableWaterNonServiceable';
import WaterLogin from 'containers/PrimoAccount/Login/Loadable';
import SomethingWentWrong from 'containers/SomethingWentWrong/Loadable';
import UnsupportedBrowser from 'containers/UnsupportedBrowser/Loadable';
import {
  pages,
  PUREFLO_SLUG,
  SECTION_HOME,
  SECTION_OFFICE,
  isCostcoDomain,
  isRegionalDomain,
  REGIONAL_DOMAIN,
  SECTION_RESI_SERVICES,
  SECTION_COMM_SERVICES,
} from 'containers/Landing/constants';
import { getDomainByHost, isAcquisitionDomain } from 'utils/domainHelper';
import RedirectLandingPage from 'containers/Landing/RedirectLandingPage/Loadable';
import PayMyBill from 'containers/PrimoProfile/PayMyBill/Loadable';
import AcquisitionPartner from 'containers/Landing/AcquisitionPartnerNonServ/Loadable';
import Promo50Page from 'containers/Landing/PromotionLandingPage/Promo50Loadable';
import Promo30Page from 'containers/Landing/PromotionLandingPage/Promo30/Loadable';
import Mover50Page from 'containers/Landing/PromotionLandingPage/Mover50/Loadable';
import Promo50bPage from 'containers/Landing/PromotionLandingPage/Promo50bLoadable';
import PromoPumpDeliveryPage from 'containers/Landing/PromotionLandingPage/PromoPumpDeliveryLoadable';
import WorkInProgress from 'containers/WorkInProgress/Loadable';

// Water Pagesdomain: REGIONAL_DOMAIN,
import HowItWorks from 'containers/Landing/HowItWorks/Loadable';
import PageNotFound from 'containers/Landing/PageNotFound/Loadable';

/**
 * To declare route use object with next params:
 * - path: (required) Route path
 * - component: (required) Route component
 * - key: (required) Key name for route entry
 * - layout: Layout component that used as wrapper for child
 * - privateRoute: restrict access to route for only authenticated users
 * - shared: if true then this page is shared between 'office' and 'home' sections, also this parameter adds ?section=office query param
 * - anonymousOnly: restrict access to route for authenticated users
 * if page was opened from 'office' section
 */

const defaultRoutes = [
  {
    path: '/terms-of-use',
    exact: true,
    component: TermsOfUse,
    key: 'terms-of-use',
    layout: BaseLayout,
    shared: true,
    domain: '*',
    fetchKey: pages.termsOfUse,
    useSsr: true,
    usedInSitemap: true,
    useInSelfServe: true,
    useLang: true,
  },
  {
    path: '/california-notice-at-collection',
    exact: true,
    component: CANoticeAtCollection,
    key: 'california-notice-at-collection',
    layout: BaseLayout,
    shared: true,
    domain: '*',
    fetchKey: pages.californiaNoticeAtCollection,
    useSsr: true,
    usedInSitemap: true,
    useInSelfServe: true,
    useLang: false,
    onlyUS: true,
  },
  {
    path: '/texas-notice-at-collection',
    exact: true,
    component: TexasNoticeAtCollection,
    key: 'texas-notice-at-collection',
    layout: BaseLayout,
    shared: true,
    domain: '*',
    fetchKey: pages.texasNoticeAtCollection,
    useSsr: true,
    usedInSitemap: true,
    useInSelfServe: true,
    useLang: false,
    onlyUS: true,
  },
  {
    path: '/terms-and-conditions',
    exact: true,
    component: TermsAndConditions,
    key: 'terms-and-conditions',
    layout: BaseLayout,
    shared: true,
    domain: '*',
    fetchKey: pages.termsAndConditions,
    useInSelfServe: true,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicy,
    key: 'privacy-statement',
    layout: BaseLayout,
    shared: true,
    domain: '*',
    fetchKey: pages.privacyPolicy,
    useSsr: true,
    usedInSitemap: true,
    useInSelfServe: true,
    useLang: true,
  },
  {
    path: '/cookie-policy',
    exact: true,
    component: CookiePolicy,
    key: 'cookie-policy',
    layout: BaseLayout,
    shared: true,
    domain: '*',
    fetchKey: pages.cookiePolicy,
    useSsr: true,
    usedInSitemap: true,
    useInSelfServe: true,
    useLang: true,
    onlyUS: true,
  },
  {
    path: '/CASupplyChain',
    exact: true,
    component: CaliforniaTransparencyAct,
    key: 'california-transparency-supply-chains-act',
    layout: BaseLayout,
    shared: true,
    domain: '*',
    fetchKey: pages.californiaTransparencyAct,
    useSsr: true,
    usedInSitemap: true,
    useInSelfServe: true,
    useLang: false,
    onlyUS: true,
  },
  {
    path: '/preview/:contentId?',
    component: ContentfulPreview,
    key: 'contentful-preview',
    privateRoute: false,
    domain: '*',
    useLang: false,
  },
  {
    path: '/start-order',
    component: WaterStartOrder,
    key: 'start-order',
    layout: BaseLayout,
    name: 'start-order',
    domain: '*',
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/how-it-works',
    component: HowItWorks,
    key: 'howItWorks',
    layout: BaseLayout,
    name: 'How It Works',
    shared: true,
    domain: '*',
    useLang: true,
  },
  {
    path: '/coffee-service',
    component: CoffeeService,
    key: 'coffeeService',
    layout: BaseLayout,
    name: 'Coffee Service',
    shared: true,
    domain: '*',
    useLang: true,
  },
  {
    path: '/filtration-service',
    component: FiltrationService,
    key: 'filtrationService',
    layout: BaseLayout,
    name: 'Filtration Service',
    shared: true,
    domain: '*',
    useLang: true,
  },
  {
    path: '/coffee-equipment',
    component: CoffeeEquipment,
    key: 'coffeeEquipment',
    layout: BaseLayout,
    name: 'Coffee Equipment',
    shared: true,
    domain: '*',
    useLang: true,
  },
  {
    path: '/water-equipment',
    component: WaterEquipment,
    key: 'waterEquipment',
    layout: BaseLayout,
    name: 'Water Equipment',
    shared: true,
    domain: '*',
    useLang: true,
  },
  {
    path: '/about-us',
    component: About,
    key: 'about',
    layout: BaseLayout,
    name: 'About Us',
    shared: true,
    domain: '*',
    useLang: true,
  },
  {
    path: '/something-went-wrong',
    component: SomethingWentWrong,
    key: 'something-went-wrong',
    layout: BaseLayout,
    name: 'something-went-wrong',
    domain: '*',
    useInSelfServe: true,
    useLang: true,
  },
  {
    path: '/work-in-progress',
    component: WorkInProgress,
    key: 'work-in-progress',
    layout: BaseLayout,
    name: 'work-in-progress',
    domain: '*',
    useInSelfServe: true,
    useLang: true,
  },
  {
    path: '/unsupported-browser',
    component: UnsupportedBrowser,
    key: 'unsupported-browser',
    layout: BaseLayout,
    name: 'unsupported-browser',
    domain: '*',
    useInSelfServe: true,
    useLang: true,
  },
];

const regionalSharedRoutes = [
  {
    path: '/cooler-cleaning-service',
    component: CoolerCleaningService,
    key: 'cooler-cleaning-service',
    layout: BaseLayout,
    name: 'cooler-cleaning-service',
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.coolerCleaningService,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
    onlyUS: true,
  },
  {
    path: '/cooler-cleaning-service-faq',
    component: CoolerCleaningService,
    key: 'cooler-cleaning-service',
    layout: BaseLayout,
    name: 'cooler-cleaning-service',
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.coolerCleaningService,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
    onlyUS: true,
  },
  {
    path: '/cooler-cleaning-service-schedule-service',
    component: CoolerCleaningService,
    key: 'cooler-cleaning-service',
    layout: BaseLayout,
    name: 'cooler-cleaning-service',
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.coolerCleaningService,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
    onlyUS: true,
  },
  {
    path: '/bottled-water-and-coffee',
    exact: true,
    component: HomeOfficeProducts,
    key: 'bottled-water-and-coffee',
    layout: BaseLayout,
    fetchKey: pages.homeOfficeProducts,
    domain: REGIONAL_DOMAIN,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/products',
    exact: true,
    component: ProductList,
    key: 'products',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.productList,
    useSsr: true,
    usedInSitemap: true,
    useInSelfServe: true,
    useLang: true,
  },
  {
    path: '/products/:categoryId',
    exact: true,
    component: ProductList,
    key: 'products',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.productListLoggedOut,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/account/products/list',
    exact: true,
    component: ProductList,
    key: 'account-products',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
    privateRoute: true,
    useInSelfServe: true,
    useLang: false,
  },
  {
    path: '/account/products/:itemNumber',
    exact: true,
    component: ProductPageLoggedIn,
    key: 'account-product-page',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
    privateRoute: true,
    useInSelfServe: true,
    useLang: false,
  },
  {
    path: '/product/:productType/:slug',
    exact: true,
    component: ProductPageLoggedOut,
    key: 'product-page',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
    fetchKey: 'product-page-logged-out',
    useSsr: true,
    useLang: true,
  },
  {
    path: '/faqs',
    exact: true,
    component: PrimoFAQ,
    key: 'faq',
    layout: BaseLayout,
    shared: true,
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.faq,
    useSsr: true,
    usedInSitemap: true,
    useInSelfServe: true,
    useLang: true,
  },
  {
    path: '/account/login',
    exact: true,
    component: WaterLogin,
    key: 'account-login',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
    anonymousOnly: true,
    useLang: false,
  },
  {
    path: '/account/profile',
    exact: true,
    component: PrimoProfile,
    key: 'account-routes',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
    privateRoute: true,
    useLang: false,
  },
  {
    path: '/account/pay-my-bill',
    exact: true,
    component: PayMyBill,
    key: 'account-routes',
    domain: REGIONAL_DOMAIN,
    privateRoute: true,
    useInMobileAppView: true,
    useLang: false,
  },
  {
    path: '/account/:activePage',
    component: PrimoAccount,
    key: 'account-routes',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
    privateRoute: true,
    useLang: false,
  },
  {
    path: '/acct-xfer',
    exact: true,
    component: AcctXfer,
    key: 'unified-acct-xfer',
    domain: REGIONAL_DOMAIN,
    privateRoute: true,
    useInSelfServe: true,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/refer',
    exact: true,
    component: Refer,
    key: 'refer',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
    privateRoute: true,
    useInSelfServe: true,
    useLang: false,
  },
  {
    path: `/checkout/:page(${primoCheckoutPages.join('|')})/:id?`,
    component: PrimoCheckoutPage,
    key: 'checkout',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
    anonymousOnly: true,
    useSsr: true,
    useLang: true,
  },
  {
    path: `/quick-shop/:page(${quickShopPages.join('|')})/:id?`,
    component: QuickShop,
    key: 'quickshop',
    layout: BaseLayout,
    fetchKey: pages.quickShop,
    domain: REGIONAL_DOMAIN,
    useSsr: true,
    usedInSitemap: true,
    // anonymousOnly: true,
    useLang: true,
  },
  {
    path: '/cart',
    component: Cart,
    key: 'cart',
    layout: BaseLayout,
    name: 'Cart',
    domain: REGIONAL_DOMAIN,
    useSsr: true,
    anonymousOnly: true,
    useLang: true,
  },
  {
    path: '/support/equipment-manuals',
    component: Support,
    key: 'equipment-manuals',
    layout: BaseLayout,
    name: 'Equipment Manuals',
    shared: true,
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.equipmentManuals,
    useSsr: true,
    usedInSitemap: true,
    useInSelfServe: true,
    useLang: true,
  },
  {
    path: '/support/contact-us',
    component: Support,
    key: 'contact-us',
    layout: BaseLayout,
    name: 'Contact Us',
    shared: true,
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.contactUs,
    useSsr: true,
    usedInSitemap: true,
    useInSelfServe: true,
    useLang: true,
  },
  {
    path: '/non-serviceable',
    component: WaterNonServiceable,
    key: 'non-serviceable',
    layout: BaseLayout,
    name: 'non-serviceable',
    domain: REGIONAL_DOMAIN,
    useLang: true,
  },
  {
    path: '/redirect-landing',
    component: RedirectLandingPage,
    key: 'redirect-landing-page',
    layout: BaseLayout,
    name: 'redirect-landing-page',
    domain: REGIONAL_DOMAIN,
    useLang: true,
  },
  {
    path: '/nonserve-partner',
    exact: true,
    component: AcquisitionPartner,
    key: 'nonserve-partner',
    layout: BaseLayout,
    shared: true,
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.acquisitionPartner,
    useSsr: false,
    usedInSitemap: false,
    useLang: true,
  },
  {
    path: '/page-not-found',
    exact: true,
    component: PageNotFound,
    key: 'page-not-found',
    layout: BaseLayout,
    shared: true,
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.pageNotFound,
    useSsr: false,
    usedInSitemap: false,
    useLang: true,
  },
  ...authRoutes,
  ...defaultRoutes,
];

const regionalOnlyRoutes = [
  {
    path: '/',
    exact: true,
    component: HomeResi,
    key: SECTION_HOME,
    layout: BaseLayout,
    fetchKey: pages.home,
    section: SECTION_HOME,
    usedInSitemap: true,
    useSsr: true,
    domain: REGIONAL_DOMAIN,
    useLang: true,
  },
  {
    path: '/home',
    exact: true,
    component: HomeResi,
    key: SECTION_HOME,
    layout: BaseLayout,
    fetchKey: pages.home,
    section: SECTION_HOME,
    usedInSitemap: true,
    useSsr: true,
    domain: REGIONAL_DOMAIN,
    useLang: true,
  },
  {
    path: '/office',
    exact: true,
    component: HomeComm,
    key: SECTION_OFFICE,
    layout: BaseLayout,
    fetchKey: pages.home,
    domain: REGIONAL_DOMAIN,
    section: SECTION_OFFICE,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/office/who-we-serve',
    component: WhoWeServe,
    key: 'who-we-serve',
    layout: BaseLayout,
    name: 'who-we-serve',
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.whoWeServe,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/home/bottled-water-delivery-service',
    exact: true,
    component: ServicesResi,
    key: SECTION_RESI_SERVICES,
    layout: BaseLayout,
    fetchKey: pages.residentialServices,
    section: SECTION_RESI_SERVICES,
    usedInSitemap: true,
    useSsr: true,
    domain: REGIONAL_DOMAIN,
    useLang: true,
  },
  {
    path: '/office/bottled-water-delivery-service',
    exact: true,
    component: ServicesComm,
    key: SECTION_COMM_SERVICES,
    layout: BaseLayout,
    fetchKey: pages.commercialServices,
    section: SECTION_COMM_SERVICES,
    usedInSitemap: true,
    useSsr: true,
    domain: REGIONAL_DOMAIN,
    useLang: true,
  },
  {
    path: '/our-story',
    component: OurStory,
    key: 'our-story',
    layout: BaseLayout,
    name: 'our-story',
    shared: true,
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.ourStory,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/about',
    component: About,
    key: 'about',
    layout: BaseLayout,
    name: 'about',
    shared: true,
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.about,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/responsibility',
    component: Responsibility,
    key: 'responsibility',
    layout: BaseLayout,
    name: 'responsibility',
    fetchKey: pages.responsibility,
    shared: true,
    domain: REGIONAL_DOMAIN,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/responsibility-environmental',
    component: Responsibility,
    key: 'responsibility',
    layout: BaseLayout,
    name: 'responsibility',
    fetchKey: pages.responsibility,
    shared: true,
    domain: REGIONAL_DOMAIN,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/responsibility-social',
    component: Responsibility,
    key: 'responsibility',
    layout: BaseLayout,
    name: 'responsibility',
    fetchKey: pages.responsibility,
    shared: true,
    domain: REGIONAL_DOMAIN,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/responsibility-governance',
    component: Responsibility,
    key: 'responsibility',
    layout: BaseLayout,
    name: 'responsibility',
    fetchKey: pages.responsibility,
    shared: true,
    domain: REGIONAL_DOMAIN,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    // DEPRECated - obsolete post ship of Responsibility
    path: '/social-and-corporate-responsibility',
    component: CorporateResponsibilityDEPREC,
    key: '/social-and-corporate-responsibility',
    layout: BaseLayout,
    name: 'social-and-corporate-responsibility',
    fetchKey: 'corporate-responsibility',
    shared: true,
    domain: REGIONAL_DOMAIN,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/office/coffee-service',
    component: LeadGen,
    key: 'lead-gen',
    layout: BaseLayout,
    name: 'Contact Us',
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.coffeeService,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/home/coffee-service',
    component: LeadGen,
    key: 'lead-gen',
    layout: BaseLayout,
    name: 'home-coffee-service',
    domain: REGIONAL_DOMAIN,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/bottled-water-quality',
    component: WaterQuality,
    key: 'bottled-water-quality',
    layout: BaseLayout,
    name: 'bottled-water-quality',
    shared: true,
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.waterQuality,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/about',
    component: About,
    key: 'about',
    layout: BaseLayout,
    name: 'about',
    shared: true,
    domain: REGIONAL_DOMAIN,
    fetchKey: pages.about,
    useSsr: true,
    usedInSitemap: true,
    useLang: true,
  },
];

const costcoOnlyRoutes = [
  {
    path: '/',
    exact: true,
    component: HomeRedirect,
    domain: 'costco',
    useSsr: true,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/home',
    exact: true,
    component: HomeRedirect,
    domain: 'costco',
    useSsr: true,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/bridge',
    exact: true,
    component: BridgeCostco,
    domain: 'costco',
    useSsr: true,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/costcowater-casupplychain',
    exact: true,
    component: CostcoWaterCASupplyChain,
    key: 'costcowater-casupplychain',
    layout: BaseLayout,
    shared: true,
    domain: 'costco',
    fetchKey: pages.costcowaterCASupplyChain,
    useSsr: false,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/costcowater-ccpa',
    exact: true,
    component: CostcowaterCCPA,
    key: 'costcowater-ccpa',
    layout: BaseLayout,
    shared: true,
    domain: 'costco',
    fetchKey: pages.costcowaterCCPA,
    useSsr: false,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/costcowater-cookies',
    exact: true,
    component: CostcowaterCookies,
    key: 'costcowater-cookies',
    layout: BaseLayout,
    shared: true,
    domain: 'costco',
    fetchKey: pages.costcowaterCookies,
    useSsr: false,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/costcowater-nonserv-absopure',
    exact: true,
    component: CostcoWaterNonServAbsopure,
    key: 'costcowater-nonserv-absopure',
    layout: BaseLayout,
    shared: true,
    domain: 'costco',
    fetchKey: pages.costcowaterNonservAbsopure,
    useSsr: false,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/costcowater-nonserv-premiumwater',
    exact: true,
    component: CostcoWaterNonServPremiumwater,
    key: 'costcowater-nonserv-premiumwater',
    layout: BaseLayout,
    shared: true,
    domain: 'costco',
    fetchKey: pages.costcowaterNonservPremiumwater,
    useSsr: false,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/costcowater-nonserv-restrictions',
    exact: true,
    component: CostcoWaterNonServ,
    key: 'costcowater-nonserv-restrictions',
    layout: BaseLayout,
    shared: true,
    domain: 'costco',
    fetchKey: pages.costcowaterNonservRestrictions,
    useSsr: false,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/costcowater-privacy',
    exact: true,
    component: CostcowaterPrivacy,
    key: 'costcowater-privacy',
    layout: BaseLayout,
    shared: true,
    domain: 'costco',
    fetchKey: pages.costcowaterPrivacy,
    useSsr: false,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/costcowater-service',
    exact: true,
    component: CostcowaterService,
    key: 'costcowater-service',
    layout: BaseLayout,
    shared: true,
    domain: 'costco',
    fetchKey: pages.costcowaterService,
    useSsr: false,
    usedInSitemap: false,
    useLang: false,
  },
  {
    path: '/costcowater-terms',
    exact: true,
    component: CostcowaterTerms,
    key: 'costcowater-terms',
    layout: BaseLayout,
    shared: true,
    domain: 'costco',
    fetchKey: pages.costcowaterTerms,
    useSsr: false,
    usedInSitemap: false,
    useLang: false,
  },
];

const purefloOnlyRoutes = [
  {
    path: '/',
    exact: true,
    component: HomepageDEPREC,
    key: SECTION_HOME,
    layout: BaseLayout,
    fetchKey: pages.home,
    section: SECTION_HOME,
    usedInSitemap: true,
    useSsr: true,
    domain: PUREFLO_SLUG,
    useLang: true,
  },
  {
    path: '/home',
    exact: true,
    component: HomepageDEPREC,
    key: SECTION_HOME,
    layout: BaseLayout,
    fetchKey: pages.home,
    section: SECTION_HOME,
    usedInSitemap: true,
    useSsr: true,
    domain: PUREFLO_SLUG,
    useLang: true,
  },
  {
    path: '/faq',
    exact: true,
    component: PurefloFAQ,
    key: 'faq',
    layout: BaseLayout,
    domain: PUREFLO_SLUG,
    fetchKey: pages.faq,
    useSsr: false,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/filtration-education',
    exact: true,
    component: FiltrationEducation,
    key: 'filtartion-education',
    layout: BaseLayout,
    fetchKey: pages.filtrationEducation,
    domain: PUREFLO_SLUG,
    useSsr: false,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/get-started',
    exact: true,
    component: GetStarted,
    key: 'get-started',
    layout: BaseLayout,
    fetchKey: pages.getStarted,
    domain: PUREFLO_SLUG,
    useSsr: false,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/products',
    exact: true,
    component: OurProducts,
    key: 'products',
    layout: BaseLayout,
    fetchKey: pages.ourProducts,
    domain: PUREFLO_SLUG,
    useSsr: false,
    usedInSitemap: true,
    useLang: true,
  },
  {
    path: '/why-us',
    exact: true,
    component: WhyPage,
    key: 'why-us',
    layout: BaseLayout,
    fetchKey: pages.whyPage,
    domain: PUREFLO_SLUG,
    useSsr: false,
    usedInSitemap: true,
    useLang: true,
  },
];

const regionalRoutes = [...regionalOnlyRoutes, ...regionalSharedRoutes];
const costcoRoutes = [...costcoOnlyRoutes, ...regionalSharedRoutes];
const purefloRoutes = [...purefloOnlyRoutes, ...defaultRoutes];

export const sharedRoutes = regionalRoutes
  .filter((route) => route.shared)
  .map((route) => route.path.replace(/:[a-z]+/, ''));

export const getDomainRoutes = (promotionLandingPagePaths) => {
  if (isIE) {
    return [
      {
        path: '*',
        exact: true,
        component: UnsupportedBrowser,
        key: 'unsupported-browser',
        layout: BaseLayout,
        shared: true,
        domain: '*',
        fetchKey: pages.unsupportedBrowser,
        useSsr: false,
        usedInSitemap: false,
      },
    ];
  }

  // All routes applied inside Route Switch, that's why NotFound component should be last in order
  const defaultRoute = {
    path: '*',
    exact: true,
    component: PageNotFound,
    key: 'page-not-found',
    layout: BaseLayout,
    shared: true,
    fetchKey: pages.pageNotFound,
    useSsr: false,
    usedInSitemap: false,
    domain: '*',
    useLang: true,
  };

  const domain = getDomainByHost();

  const routes = ((slug) => {
    if (isCostcoDomain(slug)) return [...costcoRoutes];
    if (slug === PUREFLO_SLUG) return [...purefloRoutes];
    return [...regionalRoutes];
  })(domain.slug);

  if (isRegionalDomain(domain.slug)) {
    if (promotionLandingPagePaths) {
      forEach(promotionLandingPagePaths.items, (item) => {
        const path = `/${get(item, 'fields.path')}`;
        const route = {
          path,
          exact: true,
          component:
            path === '/promo-free-pump-delivery'
              ? PromoPumpDeliveryPage
              : path === '/promo-30'
              ? Promo30Page
              : path === '/mover-50'
              ? Mover50Page
              : path === '/promo-50b'
              ? Promo50bPage
              : Promo50Page,
          privateRoute: false,
          domain: '*',
          usedInSitemap: false,
          layout: BaseLayout,
          useSsr: true,
          useLang: true,
          key: path,
        };
        routes.push(route);
      });
    }

    if (isAcquisitionDomain()) {
      // add lang route where useLang is true
      forEach(routes.slice(1), (route) => {
        if (route.useLang) {
          routes.push({ ...route, path: `/:lang${route.path}` });
        }
      });
    }

    routes.push(defaultRoute);

    if (typeof window === 'object') {
      window.PrimoRoutes = routes;
    }

    return routes;
  }

  routes.push(defaultRoute);

  return routes;
};

export default regionalRoutes;
