// import { isSsr } from 'utils/ssrHelper';
export const fetchSite = async (payload, domain, contentfulClient) => {
  const { slug } = domain;
  const params = {
    ...payload,
    query: { slug },
  };

  return contentfulClient.getEntries(params);
};

export const getPageSlugKey = (pageKey, section) => {
  if (pageKey) {
    const theSection = section || 'home';
    if (pageKey.startsWith('-')) {
      return `${theSection}${pageKey}`;
    }
    if (pageKey === 'acquisition-home' && theSection === 'office') {
      return 'acquisition-office';
    }
  }
  return pageKey;
};

export const fetchPage = async (domain, contentfulClient, key, section) => {
  const { type, page, slug } = domain;
  if (key === 'support') {
    return null;
  }
  // const notFoundPageKey =
  //   !isSsr() && window.location.pathname === '/50-off-promotion'
  //     ? '50-off-promotion'
  //     : 'faq';

  // const pageKey =
  //   key === 'notFoundPage'
  //     ? notFoundPageKey
  //     : getPageSlugKey(page[key], section);
  const pageKey = page[key];

  const siteSlug = (() => {
    if (slug.startsWith('acquisition')) return 'acquisition-unified';
    if (key.startsWith('unified-')) return 'sparkletts';
    return slug;
  })();

  const options = {
    contentType: page.type,
    query: {
      slug: pageKey ? getPageSlugKey(pageKey, section) : key,
      'site.sys.contentType.sys.id': type,
      'site.fields.slug': siteSlug,
    },
  };

  return contentfulClient.getEntries(options);
};

export const fetchProductCards = async (itemNumbers, contentfulClient) => {
  const params = {
    contentType: 'productCard',
    query: {
      'itemNumber[in]': itemNumbers.join(','),
    },
    additionalQueryParams: {
      select: 'fields.itemNumber,fields.images,fields.title',
    },
  };
  return contentfulClient.getEntries(params);
};

export const fetchProducts = async (
  itemNumbers,
  contentfulClient,
  // onlyImages = false,
) => {
  // TODO: Ignoring onlyImages flag for now, since the code below does not make sense
  const onlyImages = false;
  const params = {
    contentType: 'product',
    query: {
      'itemNumber[in]': itemNumbers.join(','),
    },
    additionalQueryParams: {
      select: `fields.specialNotes,fields.isPPS,fields.isBestSeller,fields.name,fields.itemNumber,fields.images,fields.size,fields.brand,fields.productType,fields.category,fields.isOneTimePurchase,fields.slug${
        onlyImages ? '' : ',fields.description,fields.count'
      }`,
    },
  };

  return contentfulClient.getEntries(params);
};

export const fetchProductBySlug = async (slug, contentfulClient) => {
  const params = {
    contentType: 'product',
    query: {
      slug,
    },
    additionalQueryParams: {
      select: 'fields.itemNumber',
    },
  };

  return contentfulClient.getEntries(params);
};

export const fetchProductByItemNumber = async (
  itemNumber,
  contentfulClient,
) => {
  const params = {
    contentType: 'product',
    query: {
      itemNumber,
    },
    additionalQueryParams: {
      select: 'fields.itemNumber',
    },
  };

  return contentfulClient.getEntries(params);
};

export const fetchProductTypes = async (contentfulClient) =>
  contentfulClient.getEntries({
    contentType: 'productType',
  });

export const searchFAQ = async (domain, contentfulClient, query) => {
  const options = {
    contentType: 'faqItem',
    additionalQueryParams: {
      query,
    },
  };

  return contentfulClient.getEntries(options);
};

export const fetchPromotionAssets = async (contentfulClient) => {
  const query = {
    'fields.title[in]': '17232503,41000561',
    select: 'fields.title,fields.file',
  };
  return contentfulClient.getAssets(query);
};

export const fetchNewFeatureVideo = async (contentfulClient) => {
  const query = {
    'fields.title[in]': 'New Feature Tour 11.10',
    select: 'fields.file,fields.description',
  };
  return contentfulClient.getAssets(query);
};

export const fetchImage = async (contentfulClient, title) => {
  const query = {
    'fields.title[in]': title,
    select: 'fields.file,fields.description',
  };
  return contentfulClient.getAssets(query);
};

export const fetchEntry = async (contentfulClient, payload) =>
  contentfulClient.getEntry(payload);

export const fetchAsset = async (contentfulClient, title) =>
  contentfulClient.getAssets({
    'fields.title[in]': title,
    select: 'fields.file,fields.description',
  });

export const fetchJSONData = async (contentfulClient, title) => {
  const options = {
    contentType: 'jsonData',
    additionalQueryParams: {
      'fields.entryTitle[in]': title,
    },
  };
  return contentfulClient.getEntries(options);
};

export const fetchBrandedVariables = async (contentfulClient, brand) =>
  fetchJSONData(contentfulClient, `Acquisition > Branded Variables > ${brand}`);
