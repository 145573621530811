import React, { useCallback, useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, RadioGroup } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import {
  Input,
  Radio,
  Select,
  Button,
  Checkbox,
  Dialog,
  H2,
  Div,
  Span,
} from 'components/Ui';
import { US_REGIONS } from 'utils/selectOptions';
import { useIsTabletBelow, useIsMobile } from 'utils/hooks';
import styled from 'styled-components';

import {
  BILLING_TYPE_ANOTHER,
  BILLING_TYPE_SHIPPING,
} from 'containers/PrimoCheckoutPage/constants';
import commonStyles from 'containers/PrimoCheckoutPage/styles.scss';
import { selectCartData } from 'containers/Cart/selectors';
import { isValidCardNumber } from 'utils/formValidators';
import { dataLayerPush } from 'utils/tracking';
import { InfoIcon, warningRedSmall } from 'images';
import {
  CA_EN_URL_PREFIX,
  CA_FR_URL,
  CA_FR_URL_PREFIX,
  isCanadianBrand,
  translateData,
} from 'utils/translation';
import { provinceValues } from 'utils/constants';
import { requiredCardFields, requiredBillingFields } from '../utils';
import ChecklistSmartCircle from '../ChecklistSmartCircle';
import CreditCardForm from './CreditCardForm';

import styles from './styles.scss';

const ScreenReader = styled.p`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const PaymentForm = ({
  onSubmit,
  onBack,
  storedValues,
  errors,
  isProcessing,
  changeCanGoForward,
  forwardedRef,
  isDrawer = false,
}) => {
  const defaultValues = useMemo(
    () => ({
      cardholderName: '',
      cardNumber: '',
      expirationMonth: '',
      expirationYear: '',
      address1: '',
      address2: '',
      city: '',
      stateOrProvinceCode: '',
      postalCode: '',
      countryCode: isCanadianBrand() ? 'CA' : 'US',
      billingType: BILLING_TYPE_SHIPPING,
      autoPay: false,
      displayAutopayMessage: true,
      terms: false,
      displayTermsMessage: true,
      eInvoice: false,
      displayEInvoiceMessage: true,
      statementDeliveryMethod: 'EPDF',
      checklistSmartCircle: false,
    }),
    [],
  );
  const isMobile = useIsMobile();
  const isCostco = window.isCostcoWater;
  const [values, changeValues] = useState({
    ...defaultValues,
    ...storedValues,
  });
  const [editPayment, changeEditPayment] = useState(false);
  const [modalStates, changeModalStates] = useState({
    learnMoreAutoPay: false,
    learnMoreeInvoice: false,
  });
  const showPaymentView = useMemo(
    () => Boolean(storedValues.last4 && !editPayment),
    [editPayment, storedValues.last4],
  );
  const isFilledCard =
    showPaymentView ||
    (!showPaymentView && requiredCardFields.every((field) => values[field]));
  const isFilledBilling =
    values.billingType === BILLING_TYPE_SHIPPING ||
    (values.billingType === BILLING_TYPE_ANOTHER &&
      requiredBillingFields.every((field) => values[field]));
  const isFilled =
    isFilledBilling &&
    isFilledCard &&
    isValidCardNumber(values.cardNumber) &&
    values.autoPay &&
    (isDrawer || values.terms) &&
    values.eInvoice &&
    values.checklistSmartCircle;

  const isTabletBelow = useIsTabletBelow();
  const gridSpacing = useMemo(() => (isTabletBelow ? 1 : 4), [isTabletBelow]);

  const cartData = useSelector(selectCartData());

  useEffect(() => {
    const deliveryTime =
      cartData &&
      cartData.deliverySetup &&
      cartData.deliverySetup.deliveryTime === 'morning'
        ? 'Morning'
        : 'Afternoon';

    dataLayerPush(
      'Acquisition',
      {
        event: 'checkoutOption',
        ecommerce: {
          checkout_option: {
            actionField: { step: '2', option: deliveryTime },
          },
        },
      },
      'dlA18',
    );

    dataLayerPush(
      'Acquisition',
      {
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: { step: 3 },
          },
        },
      },
      'dlA19',
    );
  }, []);

  useEffect(() => {
    if (!isDrawer) {
      changeCanGoForward(isFilled);
    }
  }, [isFilled]);

  const urlPrefix = () => {
    if (values.countryCode === 'CA') {
      const language = window.location.pathname.split('/')[1];
      return language.toLowerCase() === CA_FR_URL.toLowerCase()
        ? CA_FR_URL_PREFIX
        : CA_EN_URL_PREFIX;
    }
    return '';
  };

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'postalCode') {
      value = value.toUpperCase();
    }
    changeValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    if (!values.autoPay) {
      changeValues({ ...values, displayAutopayMessage: true });
      return;
    }

    if (!isDrawer && !values.terms) {
      changeValues({ ...values, displayTermsMessage: true });
      return;
    }

    if (!values.eInvoice) {
      changeValues({ ...values, displayEInvoiceMessage: true });
      return;
    }

    onSubmit({ values, isSaveCard: !showPaymentView });
  };

  const handleAutoPay = (checked) => {
    changeValues({
      ...values,
      autoPay: checked,
      displayAutopayMessage: !checked,
    });
  };

  const handleTerms = (checked) => {
    changeValues({
      ...values,
      terms: checked,
      displayTermsMessage: !checked,
    });
  };

  const handleEInvoice = (checked) => {
    changeValues({
      ...values,
      eInvoice: checked,
      displayEInvoiceMessage: !checked,
      statementDeliveryMethod: checked ? 'EPDF' : 'MAIL',
    });
  };

  const handleChecklistSmartCircle = useCallback(
    (isAllChecked) => {
      if (isAllChecked === values.checklistSmartCircle) return;

      changeValues({
        ...values,
        checklistSmartCircle: isAllChecked,
      });
    },
    [values],
  );

  const toggleModal = (name) =>
    changeModalStates({ ...modalStates, [name]: !modalStates[name] });
  const toggleLearnMoreAutoPay = () => toggleModal('learnMoreAutoPay');
  const toggleLearnMoreeInvoice = () => toggleModal('learnMoreeInvoice');
  const handleShowEdit = () => {
    if (!isDrawer) {
      changeValues({
        ...values,
        cardholderName: defaultValues.cardholderName,
        cardNumber: defaultValues.cardNumber,
        expirationMonth: defaultValues.expirationMonth,
        expirationYear: defaultValues.expirationYear,
      });
    } else {
      changeValues({
        ...values,
        cardNumber: defaultValues.cardNumber,
      });
    }

    changeEditPayment(true);
  };

  const WarningIcon = styled.img`
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    padding-left: 5px;
  `;

  const ErrorText = styled.div`
    background-color: #f4f5fa;
    margin-top: 5px;
    display: block;
    .error_text {
      display: inline-block;
      width: 90%;
      margin: 5px auto;
      padding-left: 5px;
    }
  `;
  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      ref={forwardedRef}
      className={!isDrawer ? styles.genericForm : styles.drawerForm}
      role="form"
    >
      {!isDrawer ? (
        <>
          <div className={styles.block}>
            <div className={commonStyles.title}>
              {showPaymentView ? (
                <div>
                  <Span>Your Payment Method</Span>:{' '}
                  <Button
                    onClick={handleShowEdit}
                    link
                    type="button"
                    text="Edit"
                    className={commonStyles.editLink}
                  />
                </div>
              ) : (
                <Div>{!isCostco ? 'Add a Credit/Debit Card:' : ''}</Div>
              )}
            </div>
            {showPaymentView ? (
              <div className={styles.paymentView}>
                <div>
                  <Span>Credit Card ending in</Span> {storedValues.last4}
                </div>
              </div>
            ) : (
              <>
                <Grid item sm={12} md={12}>
                  <div className={styles.requiredText}>
                    <Span>
                      Fields marked with an asterisk (*) are required.
                    </Span>
                  </div>
                </Grid>
                <CreditCardForm
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  isDrawer={isDrawer}
                />
              </>
            )}
          </div>
          <div className={styles.block}>
            <Div className={commonStyles.title}>Billing Address:</Div>
            <RadioGroup
              name="billingType"
              value={values.billingType}
              onChange={handleChange}
              row
              className={styles.radioGroup}
            >
              <Radio
                value={BILLING_TYPE_SHIPPING}
                label="Use my delivery address"
              />
              <Radio value={BILLING_TYPE_ANOTHER} label="Use another address" />
            </RadioGroup>
            {values.billingType === BILLING_TYPE_ANOTHER && (
              <>
                <Grid
                  {...(!isDrawer ? { container: true } : {})}
                  spacing={gridSpacing}
                >
                  <Grid item sm={12} md={isDrawer ? 12 : 6}>
                    <Input
                      label="Street Address:"
                      ariaLabel="Street address"
                      name="address1"
                      value={values.address1}
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['']}
                      error={errors.address1}
                      autocomplete="billing address-line1"
                      id="address-street-one"
                    />
                  </Grid>
                  <Grid item sm={12} md={isDrawer ? 12 : 6}>
                    <Input
                      label="Apt/Ste/Other:"
                      ariaLabel="Apartment Number, Suite Number, or Other"
                      name="address2"
                      value={values.address2}
                      onChange={handleChange}
                      error={errors.address2}
                      autocomplete="billing address-line2"
                      id="address-street-two"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={12} md={isDrawer ? 12 : 6}>
                    <Input
                      label="City:"
                      ariaLabel="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['']}
                      error={errors.city}
                      autocomplete="billing address-level2"
                      id="address-city"
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    {
                      <Select
                        placeholder="Select State"
                        ariaLabel="Select State"
                        label="State:"
                        boldLabel
                        value={values.stateOrProvinceCode}
                        items={isCanadianBrand() ? provinceValues : US_REGIONS}
                        onChange={handleChange}
                        name="stateOrProvinceCode"
                        icon="primaryArrow"
                        validators={['required']}
                        errorMessages={['']}
                        error={errors.stateOrProvinceCode}
                        autocomplete="billing address-level1"
                        id="address-state"
                      />
                    }
                  </Grid>
                  <Grid item sm={12} md={2}>
                    <Input
                      label="ZIP code:"
                      ariaLabel="ZIP or Postal Code"
                      name="postalCode"
                      value={values.postalCode}
                      onChange={handleChange}
                      validators={['required', 'zip']}
                      errorMessages={[
                        '',
                        translateData('Zip code is not valid'),
                      ]}
                      error={errors.postalCode}
                      autocomplete="billing postal-code"
                      id="address-zip"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}></Grid>
              </>
            )}
          </div>
          <div className={styles.block}>
            <div className={styles.terms}>
              <Checkbox
                isRectangle
                className={styles.checkbox}
                label="Agree to Terms & Conditions"
                ariaLabel="Agree to Terms and Conditions"
                ariaDescribedby="terms-message terms-description"
                checked={values.terms}
                onChange={handleTerms}
                validators={['terms']}
                id="terms"
              />
              <Link
                to={
                  isCostco
                    ? '/costcowater-service'
                    : `${urlPrefix()}/terms-and-conditions`
                }
                target="_blank"
                ariaLabel="Terms & Conditions"
              >
                <Span>Learn more</Span>
              </Link>
              {values.displayTermsMessage && (
                <>
                  <div
                    className={styles.autoPayErrorMessage}
                    id="terms-message"
                  >
                    <ErrorText aria-live="polite" id={'terms-message-error'}>
                      <WarningIcon src={warningRedSmall} alt="Error" />
                      <Div className="error_text">
                        You must agree to the Terms & Conditions to complete all
                        web orders.
                      </Div>
                    </ErrorText>
                  </div>
                </>
              )}
            </div>
            <div className={styles.autoPay}>
              <Checkbox
                isRectangle
                className={styles.checkbox}
                label="AutoPay: I agree to enroll in AutoPay"
                ariaLabel="Enroll in Auto Pay"
                ariaDescribedby="autopay-message autopay-description"
                checked={values.autoPay}
                onChange={handleAutoPay}
                validators={['autoPay']}
                id="autopay"
              />
              <Button
                className={styles.learnMore}
                text="Learn more"
                link
                onClick={toggleLearnMoreAutoPay}
                ariaLabel="learn more auto pay"
              />
              {values.displayAutopayMessage && (
                <>
                  <div
                    className={styles.autoPayErrorMessage}
                    id="autopay-message"
                  >
                    <ErrorText aria-live="polite" id={'autopay-message-error'}>
                      <WarningIcon src={warningRedSmall} alt="Error" />
                      <Div className="error_text">
                        AutoPay is required to complete all web orders.
                      </Div>
                    </ErrorText>
                  </div>
                </>
              )}
            </div>
            <div className={styles.autopayDescription} id="autopay-description">
              <Dialog
                size="small"
                onClose={toggleLearnMoreAutoPay}
                open={modalStates.learnMoreAutoPay}
                fullScreen={isMobile}
                aria-labelledby="Learn More Auto Pay"
              >
                <div className={styles.modal}>
                  <img src={InfoIcon} alt="" />
                  <H2 id="about-autoPay">About AutoPay</H2>
                  <Div className={styles.text}>
                    With Autopay, the credit or debit card you provided will be
                    automatically charged upon each delivery made to your
                    address. For your records a payment confirmation will be
                    emailed within 24 hours of processing your product delivery.
                  </Div>
                </div>
              </Dialog>
            </div>
            <div className={styles.autoPay}>
              <Checkbox
                isRectangle
                className={styles.checkbox}
                label="eInvoice: I agree to receive invoices electronically via email"
                checked={values.eInvoice}
                onChange={handleEInvoice}
                id="einvoice"
                ariaLabel="Enroll in Electronic Invoicing"
                ariaDescribedby="eInvoice-message einvoice-description"
                validators={['eInvoice']}
              />
              <Button
                text="Learn more"
                link
                className={styles.learnMore}
                onClick={toggleLearnMoreeInvoice}
                ariaLabel="learn more eInvoice"
              />
              {values.displayEInvoiceMessage && (
                <>
                  <div
                    className={styles.autoPayErrorMessage}
                    id="eInvoice-message"
                  >
                    <ErrorText aria-live="polite" id={'eInvoice-message-error'}>
                      <WarningIcon src={warningRedSmall} alt="Error" />
                      <Div className="error_text">
                        e-Invoice is required to complete all web orders.
                      </Div>
                    </ErrorText>
                  </div>
                </>
              )}
            </div>
            <div className={styles.checkboxText} id="einvoice-description">
              <Dialog
                size="small"
                onClose={toggleLearnMoreeInvoice}
                open={modalStates.learnMoreeInvoice}
                fullScreen={isMobile}
                aria-labelledby="learn more eInvoice"
              >
                <div className={styles.modal}>
                  <img src={InfoIcon} alt="" />
                  <H2 id="about-eInvoices">About eInvoices</H2>
                  <Div className={styles.text}>
                    All invoices will be sent to you electronically by email.
                  </Div>
                </div>
              </Dialog>
            </div>
          </div>

          <ChecklistSmartCircle onAllChecked={handleChecklistSmartCircle} />
        </>
      ) : (
        <>
          <div className={styles.block}>
            <div className={commonStyles.title}>
              {showPaymentView ? (
                <div>
                  <Span>Your Payment Method</Span>:{' '}
                  <Button
                    onClick={handleShowEdit}
                    link
                    type="button"
                    text="Edit"
                    className={commonStyles.editLink}
                  />
                </div>
              ) : (
                <Div>{!isCostco ? 'Add a Credit/Debit Card:' : ''}</Div>
              )}
            </div>
            {showPaymentView ? (
              <div className={styles.paymentView}>
                <div>
                  <Span>Credit Card ending in</Span> {storedValues.last4}
                </div>
              </div>
            ) : (
              <>
                <Grid item sm={12} md={12}>
                  <div className={styles.requiredText}>
                    <Span>
                      Fields marked with an asterisk (*) are required.
                    </Span>
                  </div>
                </Grid>
                <CreditCardForm
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  isDrawer={isDrawer}
                />
              </>
            )}
          </div>
          <div className={styles.block}>
            <Div className={commonStyles.title}>Billing Address:</Div>
            <RadioGroup
              name="billingType"
              value={values.billingType}
              onChange={handleChange}
              row
              className={styles.radioGroup}
            >
              <Radio
                value={BILLING_TYPE_SHIPPING}
                label="Use my delivery address"
              />
              <Radio value={BILLING_TYPE_ANOTHER} label="Use another address" />
            </RadioGroup>
            {values.billingType === BILLING_TYPE_ANOTHER && (
              <>
                <Grid
                  {...(!isDrawer ? { container: true } : {})}
                  spacing={gridSpacing}
                >
                  <Grid item sm={12} md={isDrawer ? 12 : 6}>
                    <Input
                      label="Street Address:"
                      ariaLabel="Street address"
                      name="address1"
                      value={values.address1}
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['']}
                      error={errors.address1}
                      autocomplete="billing address-line1"
                      id="address-street-one"
                    />
                  </Grid>
                  <Grid item sm={12} md={isDrawer ? 12 : 6}>
                    <Input
                      label="Apt/Ste/Other:"
                      ariaLabel="Apartment Number, Suite Number, or Other"
                      name="address2"
                      value={values.address2}
                      onChange={handleChange}
                      error={errors.address2}
                      autocomplete="billing address-line2"
                      id="address-street-two"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={12} md={12}>
                    <Input
                      label="City:"
                      ariaLabel="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['']}
                      error={errors.city}
                      autocomplete="billing address-level2"
                      id="address-city"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={12} md={6}>
                    <Select
                      placeholder="Select State"
                      ariaLabel="Select State"
                      label="State:"
                      boldLabel
                      value={values.stateOrProvinceCode}
                      items={isCanadianBrand() ? provinceValues : US_REGIONS}
                      onChange={handleChange}
                      name="stateOrProvinceCode"
                      icon="primaryArrow"
                      validators={['required']}
                      errorMessages={['']}
                      error={errors.stateOrProvinceCode}
                      autocomplete="billing address-level1"
                      id="address-state"
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <Input
                      label="ZIP code:"
                      ariaLabel="ZIP or Postal Code"
                      name="postalCode"
                      value={values.postalCode}
                      onChange={handleChange}
                      validators={['required', 'zip']}
                      errorMessages={[
                        '',
                        translateData('Zip code is not valid'),
                      ]}
                      error={errors.postalCode}
                      autocomplete="billing postal-code"
                      id="address-zip"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}></Grid>
              </>
            )}
          </div>
          <div className={styles.block}>
            <div className={styles.autoPay}>
              <Checkbox
                isRectangle
                defaultChecked
                className={styles.checkbox}
                label="AutoPay: I agree to enroll in AutoPay"
                ariaLabel="Enroll in Auto Pay"
                ariaDescribedby="autopay-message autopay-description"
                checked={values.autoPay}
                onChange={handleAutoPay}
                validators={['autoPay']}
              />
              <Button
                text="Learn more"
                link
                className={styles.learnMore}
                onClick={toggleLearnMoreAutoPay}
                ariaLabel="learn more auto pay"
              />
              {values.displayAutopayMessage && (
                <div
                  className={styles.autoPayErrorMessage}
                  id="autopay-message"
                >
                  <ErrorText aria-live="polite" id={'autopay-message-error'}>
                    <WarningIcon src={warningRedSmall} alt="Error" />
                    <Div className="error_text">
                      AutoPay is required to complete all web orders.
                    </Div>
                  </ErrorText>
                </div>
              )}
            </div>
            <div className={styles.checkboxText} id="autopay-description">
              <Dialog
                size="small"
                onClose={toggleLearnMoreAutoPay}
                open={modalStates.learnMoreAutoPay}
                fullScreen={isMobile}
                aria-labelledby="Learn More Auto Pay"
              >
                <div className={styles.modal}>
                  <img src={InfoIcon} alt="" />
                  <H2 id="about-autoPay">About AutoPay</H2>
                  <Div className={styles.text}>
                    With Autopay, the credit or debit card you provided will be
                    automatically charged upon each delivery made to your
                    address. For your records a payment confirmation will be
                    emailed within 24 hours of processing your product delivery.
                  </Div>
                </div>
              </Dialog>
            </div>
            <div className={styles.autoPay}>
              <Checkbox
                isRectangle
                defaultChecked
                className={styles.checkbox}
                label="eInvoice: I agree to receive invoices electronically via email"
                checked={values.eInvoice}
                onChange={handleEInvoice}
                id="einvoice"
                ariaLabel="Enroll in Electronic Invoicing"
                ariaDescribedby="einvoice-description"
                required
              />
              <Button
                text="Learn more"
                link
                className={styles.learnMore}
                onClick={toggleLearnMoreeInvoice}
                ariaLabel="learn more eInvoice"
              />
              {values.displayEInvoiceMessage && (
                <>
                  <div
                    className={styles.autoPayErrorMessage}
                    id="eInvoice-message"
                  >
                    <ErrorText aria-live="polite" id={'eInvoice-message-error'}>
                      <WarningIcon src={warningRedSmall} alt="Error" />
                      <Div className="error_text">
                        e-Invoice is required to complete all web orders.
                      </Div>
                    </ErrorText>
                  </div>
                </>
              )}
            </div>
            <div className={styles.checkboxText} id="einvoice-description">
              <Dialog
                size="small"
                onClose={toggleLearnMoreeInvoice}
                open={modalStates.learnMoreeInvoice}
                fullScreen={isMobile}
                aria-labelledby="learn more eInvoice"
              >
                <div className={styles.modal}>
                  <img src={InfoIcon} alt="" />
                  <H2 id="about-eInvoices">About eInvoices</H2>
                  <Div className={styles.text}>
                    All invoices will be sent to you electronically by email.
                  </Div>
                </div>
              </Dialog>
            </div>
          </div>
        </>
      )}

      <div
        className={classnames(
          commonStyles.actions,
          styles.block,
          styles.actions,
        )}
      >
        <ScreenReader>
          Form Navigation, Select the Back button to return to the My First
          Delivery Setup form. Select continue to the Review My Order form.
        </ScreenReader>
        <Button
          text="Back"
          color="gray"
          type="button"
          onClick={onBack}
          disabled={isProcessing}
        />
        <Button text="Continue" disabled={!isFilled} loading={isProcessing} />
      </div>
    </ValidatorForm>
  );
};

PaymentForm.propTypes = {
  storedValues: PropTypes.object,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  changeCanGoForward: PropTypes.func,
  isProcessing: PropTypes.bool,
  forwardedRef: PropTypes.object,
  isDrawer: PropTypes.bool,
};

export default PaymentForm;
