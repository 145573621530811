import React, { useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';
import get from 'lodash/get';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Desktop, BelowDesktop } from 'components/Media';
import Button from 'components/ContentfulFields/Button';
import Collapse from 'components/ContentfulFields/Collapse';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import { selectItemsCount } from 'containers/Cart/selectors';
import LoginButton from 'components/Auth/LoginButton';
import UserInfoPopper from 'components/ContentfulFields/Header/HeaderPoppers/UserInfoPopper';
import {
  isWaterRegionalTheme,
  loginURL,
  redirectToExternalURL,
} from 'utils/common';
import {
  isSelfServeDomain,
  getDomainByHost,
  isAcquisitionDomain,
  shouldHideNav,
} from 'utils/domainHelper';
import { translateData, getToUrl } from 'utils/translation';
import MobileMenu from 'components/ContentfulFields/Header/HeaderCollapses/MobileMenu';
import Logo from 'components/ContentfulFields/Logo';
import CartItemCounter from 'components/ContentfulFields/Header/HeaderPoppers/CartPopper/CartItemsCounter';
import {
  selectSection,
  selectShowCostcoMembershipDialog,
} from 'containers/Landing/selectors';
import {
  showCostcoMembershipDialog,
  hideCostcoMembershipDialog,
} from 'containers/Landing/actions';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import { isSsr } from 'utils/ssrHelper';
import { useAuth0 } from 'auth0Provider';
import CartPopper from 'components/ContentfulFields/Header/HeaderPoppers/CartPopper';
import AuthenticatedNavigation from './AuthenticatedNavigation';
import CostcoNavigation from './CostcoNavigation';
import styles from './styles.scss';

const BRAND = getDomainByHost(2).brand;

const SIGNUP_URL = `https://drink.${BRAND}.com?createAccount=Y`;

const Navigation = (props, context) => {
  const navigationRef = useRef(null);
  let shrinkOn = 450;
  const isServerRendering = isSsr();
  const getWindowHeight = () => {
    const distanceY =
      (!isServerRendering && window.pageYOffset) ||
      document.documentElement.scrollTop;
    if (navigationRef?.current) {
      const { classList, clientHeight } = navigationRef.current;

      if (distanceY > shrinkOn) {
        classList.add(styles.shrink);
        shrinkOn = 350;
      } else {
        classList.remove(styles.shrink);
        shrinkOn = 450;
      }

      if (props.changeHeight) {
        props.changeHeight(clientHeight);
      }
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (
      !isServerRendering &&
      isWaterRegionalTheme(context.stylesTheme) &&
      !isMobile
    ) {
      window.addEventListener('scroll', getWindowHeight);
    }
  }, []);

  useEffect(
    () => () => {
      if (
        !isServerRendering &&
        isWaterRegionalTheme(context.stylesTheme) &&
        !isMobile
      ) {
        window.removeEventListener('scroll', getWindowHeight);
      }
    },
    [],
  );

  const {
    entryTitle,
    fields,
    logoFields,
    match,
    location,
    activeDrawer,
    isAuthenticated,
    history,
    section,
    ...otherProps
  } = props;

  const options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        if (get(node, 'data.target.sys.contentType.sys.id') === 'collapse') {
          return (
            <li>
              <Collapse
                className={styles.collapse}
                document={node.data.target}
                data-tracking={node.data.target.fields.entryTitle}
                toggle={otherProps.openCollapse}
                active={activeDrawer}
                section={section}
                isAuthenticated={isAuthenticated}
              />
            </li>
          );
        }
        return (
          <li>
            <Button className={styles.btn} document={node.data.target}>
              {children}
            </Button>
          </li>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => children,
      [INLINES.HYPERLINK]: (node, children) => {
        const {
          data: { uri: url },
        } = node;
        const isActive = url === location.pathname;
        const linkTarget =
          url === '/costcowater-terms' ||
          url === '/costcowater-privacy' ||
          url === '/costcowater-service' ||
          url === '/costcowater-ccpa'
            ? '_blank'
            : '_self';

        return (
          <li>
            <Link
              className={classnames(styles.link, {
                [styles.active]: isActive,
              })}
              to={getToUrl(url, 'U10')}
              target={linkTarget}
            >
              {translateData(children, 'C45')}
            </Link>
          </li>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const { fields: nodeFields } = node.data.target;
        const url = `/${nodeFields.slug}`;
        const isActive = url === location.pathname;

        return (
          <Link
            className={classnames(styles.link, { [styles.active]: isActive })}
            to={getToUrl(url, 'U11')}
          >
            {translateData(children, 'C46')}
          </Link>
        );
      },
    },
  };

  const acquisitionAuth = (params = {}) => {
    const url = params.showForm === 'registration' ? SIGNUP_URL : loginURL();
    redirectToExternalURL(url);
  };

  const handleCounterClick = () => {
    if (props.itemsCount === 0) {
      history.push(getToUrl('/start-order', 'U12'));
    } else {
      history.push(getToUrl('/cart', 'U13'));
    }
  };

  if (!isServerRendering && window.isCostcoWater) {
    const costcoProps = {
      fields,
      itemsCount: props.itemsCount,
      logoFields,
      options,
      acquisitionAuth,
      handleCounterClick,
      hideCostcoMembership: props.hideCostcoMembership,
      showCostcoMembership: props.showCostcoMembership,
      isShowCostcoMembershipDialog: props.isShowCostcoMembershipDialog,
    };

    return <CostcoNavigation ref={navigationRef} {...costcoProps} />;
  }

  const navigation = documentToReactComponents(fields, options);
  let ourProducts;
  if (navigation && navigation.length > 0) {
    if (navigation.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      ourProducts = navigation[0][1];
    } else {
      [, ourProducts] = navigation;
    }
  }

  const authenticatedNavigation = (
    <AuthenticatedNavigation
      closeCollapse={otherProps.closeCollapse}
      activeDrawer={activeDrawer}
      toggleCollapse={otherProps.openCollapse}
      ourProducts={ourProducts}
    />
  );
  // Commented for future Enablement
  // const { loginWithRedirect, logout } = useAuth0();
  const { logout } = useAuth0();

  const isSelfServe = isSelfServeDomain();
  if (logoFields && logoFields.fields && !logoFields.fields.description) {
    logoFields.fields.description = `${getDomainByHost(2).name} logo`;
  }
  const showNavigaion = isSelfServe ? isAuthenticated : true;
  const isAcquisition = isAcquisitionDomain();

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.navigation, {
          [styles.selfServe]: isSelfServe,
        })}
        ref={navigationRef}
        data-tracking={GTM_TRACKING_TAGS.Header}
      >
        <Logo fields={logoFields} className={styles.logo} />
        {showNavigaion && (
          <nav
            className={styles.navbar}
            data-tracking={GTM_TRACKING_TAGS.Navigation}
          >
            <Desktop>
              {isAcquisition && !shouldHideNav(match.path) && (
                <ul className={styles.ulist}>{navigation}</ul>
              )}
              {isAuthenticated && authenticatedNavigation}
            </Desktop>
            <div
              className={styles.tooltips}
              data-tracking={GTM_TRACKING_TAGS['My Profile Tooltip']}
            >
              <Desktop>
                {isAcquisition && (
                  <Fragment>
                    <LoginButton />
                    {props.itemsCount > 0 ? (
                      <CartPopper />
                    ) : (
                      <CartItemCounter
                        count={props.itemsCount}
                        onClick={handleCounterClick}
                      />
                    )}
                  </Fragment>
                )}
                {isAuthenticated && <UserInfoPopper />}
              </Desktop>
              <BelowDesktop>
                {!isAuthenticated && (
                  <CartItemCounter
                    count={props.itemsCount}
                    onClick={handleCounterClick}
                  />
                )}
                <MobileMenu
                  fields={fields}
                  openCollapse={otherProps.openCollapse}
                  closeCollapse={otherProps.closeCollapse}
                  section={section}
                  loginWithRedirect={acquisitionAuth}
                  logout={logout}
                />
              </BelowDesktop>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
};

Navigation.propTypes = {
  entryTitle: PropTypes.string,
  fields: PropTypes.object,
  logoFields: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  openCollapse: PropTypes.func.isRequired,
  activeDrawer: PropTypes.string,
  closeCollapse: PropTypes.func.isRequired,
  changeHeight: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  itemsCount: PropTypes.number,
  section: PropTypes.string,
  showCostcoMembership: PropTypes.func,
  hideCostcoMembership: PropTypes.func,
  isShowCostcoMembershipDialog: PropTypes.bool,
  history: PropTypes.object,
};

Navigation.contextTypes = {
  stylesTheme: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  isShowCostcoMembershipDialog: selectShowCostcoMembershipDialog(),
  isAuthenticated: selectIsAuthenticated(),
  itemsCount: selectItemsCount(),
  section: selectSection(),
});

const mapDispatchToProps = {
  showCostcoMembership: showCostcoMembershipDialog,
  hideCostcoMembership: hideCostcoMembershipDialog,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navigation),
);
