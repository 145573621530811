/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import GlobalStyle from 'global-styles';
import { Helmet } from 'react-helmet';
import { get, compact, isEmpty } from 'lodash';
import cookies from 'js-cookie';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import ContentfulClient from 'utils/ContentfulClient';
import RouteBuilder from 'components/RouteBuilder';
import { UNSUPPORTED_BROWSER } from 'utils/constants';
import {
  EN_US,
  EN_CA,
  FR_CA,
  CA_EN_URL,
  CA_FR_URL,
  getToUrl,
  isCanadianBrand,
  getPrimoBrandName,
} from 'utils/translation';
import {
  loadAuthentication,
  handleMobileAppViewAuthentication,
} from 'containers/Authentication/actions';
import { AUTH_DEFAULT_EXPIRATION_TIMEOUT } from 'containers/Authentication/constants';
import {
  isPurefloDomain,
  getDomainByHost,
  isAcquisitionDomain,
  isAcquisitionOrCostcoDomain,
  getBrandNameSlugFromHostname,
} from 'utils/domainHelper';
import { dataLayerPush } from 'utils/tracking';
import ScrollToTop from 'containers/App/ScrollToTop';
import {
  selectSection,
  selectLandingData,
  selectStylesTheme,
  selectShowBrandSwitchDialog,
  selectPromotionLandingPageData,
  selectSsrReqRoute,
  selectGclId,
} from 'containers/Landing/selectors';
import {
  loadProductCategories,
  resetProductsError,
} from 'containers/PrimoProducts/actions';
import {
  selectPayMyBillError,
  selectBillingInformationError,
} from 'containers/PrimoProfile/selectors';
import {
  resetPayMyBillError,
  resetBillingInformationError,
} from 'containers/PrimoProfile/actions';
import {
  selectCustomerSummary,
  selectServiceLocationError,
  selectSelectedServiceLocationBranch,
} from 'containers/PrimoAccount/selectors';
import { resetServiceLocationError } from 'containers/PrimoAccount/actions';
import { CART_PAGE_KEY } from 'containers/Cart/constants';
import {
  setSection,
  hideBrandSwitchDialog,
  setGclId,
  navigateZipRedirect,
} from 'containers/Landing/actions';
import cartSaga from 'containers/Cart/saga';
import { clearCart, resetCartError } from 'containers/Cart/actions';
import {
  selectClearCartFlag,
  selectCartError,
  selectPromotionId,
  selectItemsCount,
  selectCartPostalCode,
} from 'containers/Cart/selectors';
import { resetCheckoutError } from 'containers/CheckoutPage/actions';
import { selectCheckoutError } from 'containers/CheckoutPage/selectors';
import { removeAccessToken } from 'utils/accessTokenManager';
import { PRIMO_ACCCOUNT_PAGE_KEY } from 'containers/PrimoAccount/constants';
import {
  USER_REGION_KEY,
  DEFAULT_USER_REGION as defaultUserRegion,
} from 'containers/UserRegion/constants';
import { LANDING_PAGE_KEY, domainMap } from 'containers/Landing/constants';
import LandingPageSaga from 'containers/Landing/saga';
import LandingPageReducer from 'containers/Landing/reducer';

import primoAccountSaga from 'containers/PrimoAccount/saga';
import primoAccountReducer from 'containers/PrimoAccount/reducer';
import userRegionSaga from 'containers/UserRegion/saga';

import primoProductsSaga from 'containers/PrimoProducts/saga';
import { PRODUCTS_KEY } from 'containers/PrimoProducts/constants';

import MessageDialog from 'components/Dialogs/MessageDialog/Loadable';
import UnsupportedBrowserDialog from 'components/Dialogs/UnsupportedBrowserDialog/Loadable';
import ClearCartDialog from 'components/Dialogs/ClearCartDialog/Loadable';
import BrandSwitchDialog from 'components/Dialogs/BrandSwitchDialog/Loadable';
import ZipMigratedDialog from 'components/Dialogs/ZipMigratedDialog/Loadable';
import SkipDeliveryModal from 'containers/PrimoAccount/NextDelivery/YourOrder/SkipDelivery/LoadableModal';
import {
  selectBrand,
  selectBranch,
  selectPostalCode,
} from 'containers/UserRegion/selectors';

import {
  selectIsAuthenticated,
  selectIsInitiated,
} from 'containers/Authentication/selectors';
import { PROMO_NAME } from 'containers/QuickShop/constants';
import Loader from 'components/Loader/index';
import { isSsr } from 'utils/ssrHelper';
import { getServerData } from 'utils/getServerData';
import {
  selectProductCategories,
  selectProductsError,
} from 'containers/PrimoProducts/selectors';
import store from 'utils/store';
import { includesIgnoreCase, redirectToAcquisition } from 'utils/common';
import { getDomainRoutes } from './routes';
import RouteProvider from './RouteProvider';
import 'theme/styles.scss';

function pageViewDataLayerPushSelfServe({
  path,
  title,
  brand,
  custtype,
  branch,
}) {
  dataLayerPush(
    'SelfServe',
    {
      event: 'pageview',
      path,
      title,
      brand,
      branch,
      custtype,
    },
    'dlS0',
  );
}

function pageViewDataLayerPushAcquisition({
  path,
  pageSlug,
  title,
  brand,
  custtype,
  branch,
  zip,
  promotionApplied,
}) {
  dataLayerPush(
    'Acquisition',
    {
      event: 'pageview',
      path,
      title,
      brand,
      branch,
      custtype,
      zip,
    },
    'dlA0',
  );
  if (pageSlug === '50-off-promotion' && !promotionApplied) {
    dataLayerPush(
      'Acquisition',
      {
        ecommerce: {
          promoView: {
            promotions: [
              {
                name: PROMO_NAME,
                position: 'Zip Bar',
              },
              {
                name: PROMO_NAME,
                position: 'Delivery Module',
              },
              {
                name: PROMO_NAME,
                position: 'Overview Module',
              },
              {
                name: PROMO_NAME,
                position: 'Offering Module',
              },
            ],
          },
        },
      },
      'dlA9',
    );
  }
}

class App extends PureComponent {
  constructor(props) {
    super(props);
    const { stylesTheme } = props;
    const isServersideRendering = isSsr();
    const isPureflo = isPurefloDomain();
    this.state = {
      stylesTheme,
      isPureflo,
      pushed: '',
      isServersideRendering,
      routes: [],
    };
    moment.locale('en');
  }

  getChildContext() {
    return { stylesTheme: this.state.stylesTheme };
  }

  static getDerivedStateFromProps(props, state) {
    const path = get(props, 'history.location.pathname');
    if (!state.path || (props.history && state.path && state.path !== path)) {
      return {
        path,
      };
    }

    if (state.stylesTheme !== props.stylesTheme) {
      return {
        stylesTheme: props.stylesTheme,
      };
    }
    return null;
  }

  async componentDidMount() {
    // lll Language componentDidMount
    window.getReduxState = this.getReduxState;
    window.PrimoPathname = get(window, 'location.pathname', '');
    const hostname = get(window, 'location.hostname', '');
    window.PrimoBrand =
      hostname === 'localhost'
        ? hostname
        : getBrandNameSlugFromHostname(hostname);

    let language = '';
    switch (localStorage.getItem('language')) {
      case CA_FR_URL:
        language = FR_CA;
        break;
      case CA_EN_URL:
        language = EN_CA;
        break;
      default:
        language = navigator.language;
    }
    window.PrimoLanguage = language;

    const supportedLanguages = [EN_US, EN_CA, FR_CA];
    if (!supportedLanguages.includes(window.PrimoLanguage)) {
      window.PrimoLanguage = isCanadianBrand() ? EN_CA : EN_US;
    }

    const contentfulClient = new ContentfulClient();
    const promotionLandingPagePaths = await contentfulClient.getEntries({
      contentType: 'promotionLandingPagePaths',
    });
    const routes = getDomainRoutes(promotionLandingPagePaths);
    this.setState({ routes });
    const params = new URLSearchParams(window.location.search);
    if (params) {
      const gclId = params.get('gclid');
      if (gclId) {
        window.gclId = gclId;
        this.props.setGclId(gclId);
      }
    }
    if (!this.state.isServersideRendering && !this.state.isPureflo) {
      if (
        !this.props.isAuthenticated &&
        cookies.get('isMobileView') === 'Y' &&
        !window.isCostcoWater
      ) {
        this.props.handleMobileAppViewAuthentication();
      }

      this.props.loadAuthentication();

      const expirationTimeout =
        parseInt(
          getServerData('AUTH_EXPIRATION_TIMEOUT') ||
            AUTH_DEFAULT_EXPIRATION_TIMEOUT,
          10,
        ) * 1000;

      this.checkSessionInterval = setInterval(
        this.props.loadAuthentication,
        expirationTimeout,
      );

      if (isEmpty(this.props.productCategories)) {
        this.props.loadProductCategories();
      }

      window.addEventListener('beforeunload', this.handleWindowClosing);

      if (isAcquisitionDomain()) {
        this.refreshWithCartInfo();
      }
    }
  }

  componentWillUnmount = () => {
    if (this.checkSessionInterval) {
      clearInterval(this.checkSessionInterval);
    }
  };

  refreshWithCartInfo = () => {
    // Since the cart retrival, zip code detection, and brand retrival are asynchronous,
    // repeat 10 times or until the cart and default zip code is loaded then
    // if the cart has a different zip code than the default zip code
    // refresh the UI using the cart zip code
    const confirmedPostalCode = localStorage.getItem('confirmedPostalCode');
    const confirmedUserRegion = localStorage.getItem('confirmedUserRegion');
    let count = 0;
    const theInterval = setInterval(() => {
      const { itemsCount, cartPostalCode, userRegionPostalCode } = this.props;
      count += 1;
      if (
        count > 10 ||
        (userRegionPostalCode &&
          (!cartPostalCode || cartPostalCode === userRegionPostalCode))
      ) {
        clearInterval(theInterval);
      }
      if (
        itemsCount &&
        userRegionPostalCode &&
        userRegionPostalCode !== cartPostalCode
      ) {
        clearInterval(theInterval);
        this.props.navigateZipRedirect({
          zip: cartPostalCode,
          stay: true,
          clearCart: false,
        });
      } else if (userRegionPostalCode) {
        if (confirmedPostalCode) {
          clearInterval(theInterval);
          if (
            !confirmedUserRegion ||
            confirmedPostalCode !== this.props.userRegionPostalCode
          ) {
            this.props.navigateZipRedirect({
              zip: confirmedPostalCode,
              stay: true,
              clearCart: false,
            });
          }
        }
      }
    }, 1000);
  };

  getReduxState = () => store.getState().toJS();

  handleCloseErrorMessageDialog = () => {
    this.props.resetCartError();
    this.props.resetProductsError();
    this.props.resetCheckoutError();
    this.props.resetPayMyBillError();
    this.props.resetServiceLocationError();
    this.props.resetBillingInformationError();
  };

  handleWindowClosing = () => {
    // checkAuth on didMount will get actual token everytime
    // To avoid API request with expired or incorrect token we need to clear every time we close page
    removeAccessToken();
  };

  handlePageChange = ({ title }) => {
    const {
      customerSummary: { custType },
      userRegionPostalCode,
      userRegionBranch,
      section,
      promotionLandingPageData,
      selectedBranch,
      promotionApplied,
    } = this.props;

    if (!title) {
      return;
    }

    const path = get(this.props, 'history.location.pathname', '');
    const pushed = path;

    const isAcquisition = isAcquisitionOrCostcoDomain();

    if (pushed !== this.state.pushed) {
      const { name: brand } = getDomainByHost(5);

      if (!isAcquisition && path === '/' && title !== brand) {
        return;
      }

      const custtype = isAcquisition
        ? section === 'office'
          ? 'ORGANIZATION'
          : 'PERSON'
        : custType;

      if (isAcquisition) {
        const zip = userRegionPostalCode;
        const pageSlug = get(promotionLandingPageData, 'pageSlug', '');
        pageViewDataLayerPushAcquisition({
          path,
          pageSlug,
          title,
          custtype,
          brand,
          branch: userRegionBranch,
          zip,
          promotionApplied,
        });
        this.setState({ pushed });
      } else if (selectedBranch) {
        pageViewDataLayerPushSelfServe({
          path,
          title,
          custtype,
          brand,
          branch: selectedBranch,
        });
        this.setState({ pushed });
      }
    }
  };

  clearLocalStorage = () => {
    localStorage.removeItem('confirmedPostalCode');
    localStorage.removeItem('cartId');
    localStorage.removeItem('cartSecret');
  };

  handleBrandSwitch = () => {
    const {
      hideBrandSwitchDialog: hideBrandSwitch,
      location: { pathname, search },
      userRegionBrand,
      promotionLandingPageData,
      gclId,
    } = this.props;

    let theUserRegionBrand = window.redirectBrand
      ? window.redirectBrand
      : userRegionBrand;
    theUserRegionBrand = theUserRegionBrand.replace(' ', '-');
    delete window.redirectBrand;
    delete window.redirectPostalCode;

    // FIXME this if condition seems inevitably true, no user action can falsify it
    if (theUserRegionBrand !== defaultUserRegion.brand) {
      const { slug, brand } = getDomainByHost(6);
      const params = [];

      if (search) params.push(search);

      const globalParam = dataLayer.filter((event) => event.globalLinkerParam);
      const linkParam =
        globalParam && globalParam[0] && globalParam[0].globalLinkerParam;
      if (linkParam) params.push(linkParam); // linkParam has _ga data attribute

      const paramsObj = new URLSearchParams(params.join('&'));

      if (gclId != null) paramsObj.set('gclid', `${gclId}`);

      if (promotionLandingPageData && promotionLandingPageData.path) {
        const theBrand = (brand || slug).replace(new RegExp('-', 'g'), '_');
        const promotion = promotionLandingPageData.path.replace(
          new RegExp('-', 'g'),
          '_',
        );
        paramsObj.set('property_key', `${theBrand}_${promotion}`);
      }

      const newSearch = `${paramsObj}` ? `?${paramsObj}` : '';

      this.clearLocalStorage();
      redirectToAcquisition(theUserRegionBrand, `${pathname}${newSearch}`);
      return;
    }

    this.clearLocalStorage();
    // FIXME the code below appears to be an unreachable use case
    redirectToAcquisition(theUserRegionBrand, `${pathname}${search}`);
    hideBrandSwitch();
  };

  handleBrandSwitchStay = () => {
    const { hideBrandSwitchDialog: hideBrandSwitch } = this.props;

    hideBrandSwitch();
  };

  handleClearCartShop = () => {
    this.props.clearCart();
    this.props.history.push({
      pathname: getToUrl('/quick-shop/select-water', 'U65'),
    });
  };

  render() {
    const {
      props: {
        location: { pathname },
        site: {
          components: { page },
          name: siteName,
        },
        isBrandSwitchDialogVisible,
        isCartClear,
        userRegionBrand,
        cartError,
        productsError,
        checkoutError,
        payMyBillError,
        serviceLocationError,
        billingInformationError,
      },
      state: { stylesTheme, isServersideRendering },
    } = this;
    const routes = isSsr() ? getDomainRoutes() : this.state.routes;
    const currentRoute = routes.find((route) => route.path === pathname);
    const pageTitle = compact([
      currentRoute && currentRoute.name,
      siteName,
    ]).join(' > ');
    const userRegionDomain = domainMap[userRegionBrand];
    const brandName = getPrimoBrandName();

    const metaData = {
      title: page?.metaData?.[brandName]?.title || page?.title || pageTitle,
      description:
        page?.metaData?.[brandName]?.description || page?.description || null,
    };

    if (
      !isServersideRendering &&
      !this.state.isPureflo &&
      !window.isCostcoWater &&
      !this.props.isInitiated &&
      !isAcquisitionDomain()
    ) {
      return <Loader />;
    }

    const errorMessage =
      cartError ||
      productsError ||
      checkoutError ||
      serviceLocationError ||
      payMyBillError ||
      billingInformationError;
    const unsupportedBrowser = errorMessage === UNSUPPORTED_BROWSER;
    const showNoIndex = page && !!page.noIndex;

    let alternateLink;
    let hrefLang;
    if (!isSsr()) {
      const { href } = window.location;
      if (includesIgnoreCase(CA_EN_URL, href)) {
        hrefLang = 'fr';
        alternateLink = href.replace(/ca-en/gi, CA_FR_URL);
      }
      if (includesIgnoreCase(CA_FR_URL, href)) {
        hrefLang = 'en';
        alternateLink = href.replace(/ca-fr/gi, CA_EN_URL);
      }
    }

    return (
      <Fragment>
        <RouteProvider>
          <Switch>
            {routes.map((routeProps) => (
              <RouteBuilder {...routeProps} />
            ))}
          </Switch>
        </RouteProvider>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          onChangeClientState={(helmetState) =>
            this.handlePageChange(helmetState)
          }
        >
          {showNoIndex && (
            <meta name="robots" content="noindex, nofollow"></meta>
          )}
          {(isEmpty(page) || !page.slug.includes('product-page')) && (
            <title>{metaData.title}</title>
          )}
          {!isSsr() && (
            <link rel="canonical" href={window.location.href.split('?')[0]} />
          )}
          {alternateLink && (
            <link rel="alternate" hrefLang={hrefLang} href={alternateLink} />
          )}
          <meta name="description" content={metaData.description} />
        </Helmet>
        <ScrollToTop />
        <SkipDeliveryModal />
        {!isServersideRendering && !unsupportedBrowser && errorMessage && (
          <MessageDialog
            open
            onClose={() => this.handleCloseErrorMessageDialog()}
            message={errorMessage}
            isErrorMessage
          />
        )}
        {unsupportedBrowser && <UnsupportedBrowserDialog />}
        {!isServersideRendering &&
          !isPurefloDomain() &&
          isBrandSwitchDialogVisible && (
            <BrandSwitchDialog
              open={isBrandSwitchDialogVisible}
              onStay={this.handleBrandSwitchStay}
              onContinue={this.handleBrandSwitch}
              servicedByBrand={userRegionDomain.name}
            />
          )}
        <ZipMigratedDialog />
        {isCartClear && (
          <ClearCartDialog
            isCartClear={isCartClear}
            handleClearCartShop={this.handleClearCartShop}
          />
        )}
        <GlobalStyle theme={stylesTheme} />
      </Fragment>
    );
  }
}

App.childContextTypes = {
  stylesTheme: PropTypes.string,
  setSection: PropTypes.func,
};

App.propTypes = {
  showBrandSwitchDialog: PropTypes.bool,
  hideBrandSwitchDialog: PropTypes.func,
  setGclId: PropTypes.func,
  userRegionBrand: PropTypes.string,
  userRegionBranch: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  loadAuthentication: PropTypes.func,
  loadProductCategories: PropTypes.func,
  handleMobileAppViewAuthentication: PropTypes.func,
  isInitiated: PropTypes.bool,
  payMyBillError: PropTypes.string,
  serviceLocationError: PropTypes.string,
  cartError: PropTypes.string,
  productsError: PropTypes.string,
  checkoutError: PropTypes.string,
  billingInformationError: PropTypes.bool,
  productCategories: PropTypes.array,
  ssrReqRoute: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  site: selectLandingData(),
  stylesTheme: selectStylesTheme(),
  userRegionBrand: selectBrand(),
  userRegionBranch: selectBranch(),
  userRegionPostalCode: selectPostalCode(),
  section: selectSection(),
  customerSummary: selectCustomerSummary(),
  isBrandSwitchDialogVisible: selectShowBrandSwitchDialog(),
  isCartClear: selectClearCartFlag(),
  isAuthenticated: selectIsAuthenticated(),
  isInitiated: selectIsInitiated(),
  payMyBillError: selectPayMyBillError(),
  serviceLocationError: selectServiceLocationError(),
  cartError: selectCartError(),
  productsError: selectProductsError(),
  checkoutError: selectCheckoutError(),
  billingInformationError: selectBillingInformationError(),
  promotionLandingPageData: selectPromotionLandingPageData(),
  productCategories: selectProductCategories(),
  ssrReqRoute: selectSsrReqRoute(),
  selectedBranch: selectSelectedServiceLocationBranch(),
  promotionApplied: selectPromotionId(),
  gclId: selectGclId(),
  itemsCount: selectItemsCount(),
  cartPostalCode: selectCartPostalCode(),
});

const mapDispatchToProps = {
  loadAuthentication,
  loadProductCategories,
  setSection,
  setGclId,
  hideBrandSwitchDialog,
  handleMobileAppViewAuthentication,
  clearCart,
  resetCartError,
  resetCheckoutError,
  resetProductsError,
  resetPayMyBillError,
  resetServiceLocationError,
  resetBillingInformationError,
  navigateZipRedirect,
};

const withCartSaga = injectSaga({ key: CART_PAGE_KEY, saga: cartSaga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withAccountSaga = injectSaga({
  key: PRIMO_ACCCOUNT_PAGE_KEY,
  saga: primoAccountSaga,
});
const withAccountReducer = injectReducer({
  key: PRIMO_ACCCOUNT_PAGE_KEY,
  reducer: primoAccountReducer,
});

const withUserRegionSaga = injectSaga({
  key: USER_REGION_KEY,
  saga: userRegionSaga,
});

const withLandingPageReducer = injectReducer({
  key: LANDING_PAGE_KEY,
  reducer: LandingPageReducer,
});

const withLandingPageSaga = injectSaga({
  key: LANDING_PAGE_KEY,
  saga: LandingPageSaga,
});

const withProductsSaga = injectSaga({
  key: PRODUCTS_KEY,
  saga: primoProductsSaga,
});

export default compose(
  withRouter,
  withConnect,
  withCartSaga,
  withAccountSaga,
  withAccountReducer,
  withUserRegionSaga,
  withLandingPageReducer,
  withLandingPageSaga,
  withProductsSaga,
)(App);
