import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import { Button as MUIButton, CircularProgress } from '@material-ui/core';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import { translateData, getToUrl } from 'utils/translation';
import { HiddenContentSpan } from '../ScreenReader';

const Button = (
  {
    href,
    type,
    ariaLabel,
    link,
    name,
    text,
    icon,
    iconPosition,
    iconButton,
    className,
    onClick,
    loading,
    disabled,
    style,
    capitalize,
    color,
    fullWidth,
    fullSend,
    bold,
    ariaExpanded,
    defaultText,
    ...props
  },
  { stylesTheme },
) => {
  const theAriaLabel = ariaLabel || props['aria-label'];
  const translatedAriaLabel = translateData(theAriaLabel, '');
  const translatedText = translateData(text, defaultText);
  const dispatch = useDispatch();
  const styles = require(`./styles/${stylesTheme}.scss`);
  const buttonStyle = classNames(styles.button, className, {
    [styles.link]: link,
    [styles.disabled]: loading || disabled,
    [styles.capitalize]: capitalize,
    [styles[color]]: color,
    [styles.iconButton]: iconButton,
    [styles.fullWidth]: fullWidth,
    [styles.fullSend]: fullSend,
    [styles.bold]: bold,
  });

  const dataTracking = props['data-tracking'] || translatedText;

  // Show unmapped keys
  // console.log(!GTM_TRACKING_TAGS[dataTracking] ? dataTracking : '');

  const buttonType = type || (link ? 'button' : 'submit');

  const doClick = href
    ? href.includes('http')
      ? () => window.open(href)
      : () => dispatch(push(getToUrl(href, 'U26')))
    : onClick;

  return (
    <div className={buttonStyle}>
      <MUIButton
        onClick={doClick}
        type={buttonType}
        name={name}
        disableRipple
        disabled={loading || disabled}
        style={style}
        classes={{ focusVisible: color === 'dark' && styles.focusVisibleDark }}
        {...props}
        data-tracking={
          GTM_TRACKING_TAGS[dataTracking]
            ? GTM_TRACKING_TAGS[dataTracking]
            : dataTracking
        }
        // data-tracking={GTM_TRACKING_TAGS[dataTracking] ? GTM_TRACKING_TAGS[dataTracking] : 'MISSING'} for debugging
      >
        {iconButton ? (
          <img src={icon} alt="" />
        ) : (
          <Fragment>
            {iconPosition === 'left' && icon}
            {translatedAriaLabel && (
              <span aria-hidden="true" className={styles.buttonText}>
                {translatedText}
              </span>
            )}
            {!translatedAriaLabel && <>{translatedText}</>}
            {iconPosition === 'right' && icon}
          </Fragment>
        )}
        {translatedAriaLabel && (
          <HiddenContentSpan>{translatedAriaLabel}</HiddenContentSpan>
        )}
      </MUIButton>
      {loading && (
        <CircularProgress size={24} className={styles.buttonProgress} />
      )}
      {iconButton && <div className={styles.iconText}>{translatedText}</div>}
    </div>
  );
};

Button.propTypes = {
  icon: PropTypes.any,
  link: PropTypes.bool,
  iconButton: PropTypes.bool,
  name: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  style: PropTypes.object,
  capitalize: PropTypes.bool,
  fullWidth: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  color: PropTypes.oneOf(['contrast', 'dark', 'gray', 'primary']),
  bold: PropTypes.bool,
  ariaExpanded: PropTypes.bool,
};

Button.defaultProps = {
  icon: null,
  link: false,
  iconButton: false,
  text: '',
  onClick: () => {},
  loading: false,
  disabled: false,
  href: null,
  capitalize: false,
  iconPosition: 'left',
  color: null,
  bold: false,
  ariaExpanded: false,
};

Button.contextTypes = {
  stylesTheme: PropTypes.string,
};

export default Button;
