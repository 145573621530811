import startCase from 'lodash/startCase';
import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { PRIMO_ACCCOUNT_PAGE_KEY } from './constants';

const selectAccount = () => (state) =>
  state.get(PRIMO_ACCCOUNT_PAGE_KEY, Map());

const selectServiceLocationData = () =>
  createSelector(selectAccount(), (data) => data.get('serviceLocation'));

export const selectServiceLocationIsFetching = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isFetching'),
  );

export const selectIsFetchingCalendarDates = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isFetchingDates'),
  );

export const selectServiceLocationError = () =>
  createSelector(selectServiceLocationData(), (data) =>
    data ? data.get('error') : null,
  );

export const selectServiceLocations = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('serviceLocations').toJS(),
  );

export const selectBillingLocations = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('billingLocations').toJS(),
  );

export const selectBillToId = () =>
  createSelector(selectServiceLocationData(), (data) => {
    const result = data && data.get('billingLocations').toJS();
    return result?.[0]?.id;
  });

export const selectHasMultiServiceLocations = () =>
  createSelector(selectServiceLocations(), (locs) => locs.length > 1);

export const selectSelectedServiceLocation = () =>
  createSelector(selectServiceLocationData(), (data) => {
    const result = data && data.get('selectedLocation');
    return result ? result.toJS() : result;
  });

export const selectIsSubscriber = () =>
  createSelector(selectSelectedServiceLocation(), (data) => {
    const result = data && data?.oneTimeCustFlag;
    return result !== 'Y';
  });

export const selectAllLocationBranchCodes = () =>
  createSelector(selectServiceLocations(), (locs) =>
    locs.map((loc) => loc.branchCode),
  );

export const selectLocationBranchCode = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.getIn(['selectedLocation', 'branchCode']),
  );

export const selectLocationBrandName = () => {
  const normalizeBrandName = (brandName) => {
    if (!brandName) return brandName;

    const name = startCase(brandName.toLowerCase());

    // Fixing that this brand ends in 'Water' when 3 other brands could, but don't.
    if (name === 'Deep Rock Water') return 'Deep Rock';

    return name;
  };

  return createSelector(selectServiceLocationData(), (data) => {
    if (!data) return data;
    const brandName = data.getIn(['selectedLocation', 'brandName']);
    return normalizeBrandName(brandName);
  });
};

export const selectSelectedRecurringDeliveryId = () =>
  createSelector(selectSelectedServiceLocation(), (data) =>
    data && data.recurringDeliveryId ? data.recurringDeliveryId : null,
  );

export const selectSelectedNextDeliveryDate = () =>
  createSelector(selectSelectedServiceLocation(), (data) =>
    data ? data.nextDeliveryDate : null,
  );

export const selectSelectedServiceLocationId = () =>
  createSelector(selectSelectedServiceLocation(), (data) =>
    data ? data.id : null,
  );

export const selectSelectedServiceLocationBranch = () =>
  createSelector(selectSelectedServiceLocation(), (data) =>
    data ? data.branchCode : null,
  );

export const selectIsSelectedServiceLocationExcluded = () =>
  createSelector(selectSelectedServiceLocation(), (data) =>
    data
      ? data.excludeFlag !== 'N' && data.status !== 'Active - On Hold'
      : false,
  );

export const selectIsSelectedServiceIndefiniteHold = () =>
  createSelector(selectSelectedServiceLocation(), (data) =>
    data ? data.status === 'Active - On Hold' : false,
  );

export const selectSelectedServiceLocationStateOrProvinceCode = () =>
  createSelector(selectSelectedServiceLocation(), (data) =>
    data ? data.stateOrProvinceCode : null,
  );

export const selectCalendarDates = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('calendarDates').toJS(),
  );
export const selectHasConfirmOrder = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('hasConfirmOrder'),
  );

export const selectDeliveryAfterSkip = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('deliveryAfterSkip'),
  );

export const selectIsFetchingRecentTransactions = () =>
  createSelector(
    selectAccount(),
    (data) =>
      data &&
      data.getIn(['recentTransactions', 'isFetchingRecentTransactions']),
  );

export const selectRecentTransactions = () =>
  createSelector(
    selectAccount(),
    (data) => data && data.getIn(['recentTransactions', 'transactions']),
  );

export const selectIsFetchingRecentActivities = () =>
  createSelector(
    selectAccount(),
    (data) =>
      data && data.getIn(['recentActivities', 'isFetchingRecentActivities']),
  );

export const selectRecentUnBilledTransactions = () =>
  createSelector(
    selectAccount(),
    (data) => data && data.getIn(['recentActivities', 'unBilledTransactions']),
  );

export const selectBillingStatements = () =>
  createSelector(selectAccount(), (data) =>
    data.getIn(['billingStatements', 'data'])
      ? data.getIn(['billingStatements', 'data']).toJS()
      : [],
  );

export const selectBillingStatementsIsFetching = () =>
  createSelector(
    selectAccount(),
    (data) => data && data.getIn(['billingStatements', 'isFetching']),
  );

export const selectRecurringDataIsLoading = () =>
  createSelector(
    selectServiceLocationIsFetching(),
    selectServiceLocationIsFetchingOrder(),
    (isServiceLocationFetching, isRecurringOrderFetching) =>
      isServiceLocationFetching || isRecurringOrderFetching,
  );

export const selectRecentlyPurchased = () =>
  createSelector(
    selectAccount(),
    (data) => data && data.get('recentlyPurchased').toJS(),
  );

export const selectIsEditingOrder = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isEditingOrder'),
  );

export const selectIsEditingDeliveryOrder = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isEditingDeliveryOrder'),
  );

export const selectRecurringOrder = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('recurringOrder'),
  );

export const selectServiceLocationIsFetchingOrder = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isFetchingOrder'),
  );

export const selectServiceLocationIsSkipProcessing = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isSkipProcessing'),
  );

export const selectServiceLocationIsShowSkipDialog = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isShowSkipDialog'),
  );

export const selectServiceLocationIsSuccessUpdatedOrder = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('orderSuccessUpdated'),
  );

export const selectServiceLocationIsSuccessUpdatedDeliveryOrder = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('deliveryOrderSuccessUpdated'),
  );

export const selectServiceLocationIsSuccessSkippedDeliveryOrder = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('skipDeliveryOrderSuccessUpdated'),
  );

export const selectServiceLocationIsRecurringOrderUpdating = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isRecurringOrderUpdating'),
  );

export const selectServiceLocationIsDeliveryOrderUpdating = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isDeliveryOrderUpdating'),
  );

export const selectServiceLocationIsAddDeliveryProcessing = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isAddDeliveryProcessing'),
  );

export const selectShowOrderChangeMessage = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('showOrderChangeMessage'),
  );

export const selectBillingAccountId = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.getIn(['selectedLocation', 'billingAccountId']),
  );

export const selectRecentTransactionAddresses = () =>
  createSelector(
    selectBillingAccountId(),
    selectServiceLocations(),
    (billingAccountId, serviceLocations) =>
      serviceLocations.filter(
        (location) => location.billingAccountId === billingAccountId,
      ),
  );

export const selectCountryCode = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.getIn(['selectedLocation', 'countryCode']),
  );

export const selectServiceLocationId = () =>
  createSelector(selectServiceLocationData(), (data) => {
    const [serviceLocations] = data ? data.get('serviceLocations').toJS() : [];
    return serviceLocations ? serviceLocations.id : null;
  });

export const selectServiceLocationEquipment = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('equipment'),
  );

const selectPaymentData = () =>
  createSelector(selectAccount(), (data) => data && data.get('payment'));

export const selectPaymentSubmitted = () =>
  createSelector(selectPaymentData(), (data) => data && data.get('submitted'));

export const selectPaymentFetching = () =>
  createSelector(selectPaymentData(), (data) => data && data.get('isFetching'));

export const selectPaymentError = () =>
  createSelector(selectPaymentData(), (data) => data && data.get('error'));

export const selectDeliveryIsFetching = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isFetchingDelivery'),
  );

export const selectNextDelivery = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('nextDelivery').toJS(),
  );

export const selectNextDeliveryItems = () =>
  createSelector(selectNextDelivery(), (data) => (data ? data.items : []));

export const selectDeliveryOrderIsFetching = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isFetchingDeliveryOrder'),
  );

export const selectDeliveryOrder = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('deliveryOrder'),
  );

export const selectShopDeliveryOrder = () =>
  createSelector(selectServiceLocationData(), (data) =>
    data ? data.get('shopDeliveryOrder') : null,
  );

export const selectIsFirstDelivery = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isFirstDelivery'),
  );

export const selectCustomerSummaryIsFetching = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isFetchingCustomerSummary'),
  );

export const selectCustomerSummary = () =>
  createSelector(selectServiceLocationData(), (data) =>
    data ? data.get('customerSummary')?.toJS() : {},
  );

export const selectDeliveryIsLoading = () =>
  createSelector(
    selectServiceLocationIsFetching(),
    selectDeliveryIsFetching(),
    selectIsFetchingCalendarDates(),
    (
      isServiceLocationFetching,
      isDeliveryIsFetching,
      isCalendarDatesIsFetching,
    ) =>
      isServiceLocationFetching ||
      isDeliveryIsFetching ||
      isCalendarDatesIsFetching,
  );

export const selectIsCreatingExtraDelivery = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isCreatingExtraDelivery'),
  );

export const selectBestDeliveryDates = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('bestDeliveryDates'),
  );

export const selectIsSelectLocationProcessing = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isSelectLocationProcessing'),
  );

export const selectDeliveryInstructionsStatus = () =>
  createSelector(
    selectAccount(),
    (data) => data && data.get('deliveryInstructions').toJS(),
  );

export const selectIsDeliveryUpdating = () =>
  createSelector(
    selectServiceLocationData(),
    (data) => data && data.get('isDeliveryUpdating'),
  );

const selectmobileAppBillPayData = () =>
  createSelector(
    selectAccount(),
    (data) => data && data.get('mobileAppBillPay'),
  );

export const selectShowSomethingWentWrongList = () =>
  createSelector(
    selectAccount(),
    (data) => data && data.get('showSomethingWentWrongList'),
  );

export const selectFetchPayBillDataError = () =>
  createSelector(
    selectmobileAppBillPayData(),
    (data) => data && data.get('errorMessage'),
  );

export const selectLoadServiceLocationIsFetching = () =>
  createSelector(
    selectServiceLocationIsFetching(),
    selectIsSelectLocationProcessing(),
    (isServiceLocationFetching, isSelectLocationProcessing) =>
      isServiceLocationFetching || isSelectLocationProcessing,
  );

export const selectContactUsSubjects = () =>
  createSelector(
    selectAccount(),
    (data) => data && data.get('contactUsSubjects').toJS().data,
  );

export const selectContactUsIsSending = () =>
  createSelector(
    selectAccount(),
    (data) => data && data.getIn(['contactUsSubjects', 'isSending']),
  );
