import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { Tooltip, Button } from 'components/Ui';
import ZipForm from 'components/Forms/ZipForm';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { selectZipCode } from 'containers/ClientLocationProvider/selectors';
import {
  selectShowInitialZipDialog,
  selectPromotionLandingPageData,
  selectZipCodeConfirmed,
  selectSsrReqRoute,
} from 'containers/Landing/selectors';
import { hideInitialZipDialog as hideInitialZipDialogAction } from 'containers/Landing/actions';
import { isSsr } from 'utils/ssrHelper';
import { RoundedPencilIcon } from 'components/Ui/CustomIcons';
import styles from './styles.scss';

const ZipCodeTooltip = React.forwardRef(
  (
    {
      isShowInitialZipDialog,
      hideInitialZipDialog,
      promotionLandingPageData,
      isZipCodeConfirmed,
      ssrReqRoute,
      postalCode,
      locationPostalCode,
    },
    ref,
  ) => {
    const isServersideRendering = isSsr();
    const pathname = isServersideRendering
      ? ssrReqRoute.routePath
      : window.location.pathname;
    const atHome =
      pathname === '/' ||
      pathname === '/home' ||
      pathname === '/office' ||
      pathname ===
        `/${promotionLandingPageData ? promotionLandingPageData.path : ''}`;
    const [open, toggleTooltip] = useState(atHome && isShowInitialZipDialog);

    useEffect(() => {
      if (
        isZipCodeConfirmed &&
        (pathname.includes('product') ||
          pathname.includes('quick-shop') ||
          pathname.includes('bottled-water'))
      ) {
        const showPopperValue = localStorage.getItem('showPopper');
        if (showPopperValue !== 'false') {
          openTooltip();
        }
      } else {
        closeTooltip();
      }
    }, [isZipCodeConfirmed]);

    const handleTooltip = (e) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      toggleTooltip(typeof e === 'boolean' ? e : !open);
    };

    const openTooltip = () => {
      toggleTooltip(true);
    };

    const closeTooltip = () => {
      hideInitialZipDialog();
      toggleTooltip(false);
    };

    const anchor = (
      <Button
        ref={ref}
        link
        className={styles.zipChangeButton}
        icon={<RoundedPencilIcon />}
        iconPosition="right"
        onClick={handleTooltip}
        aria-expanded={open}
        id={isZipCodeConfirmed ? 'Change01' : 'Deliver to01'}
        role="button"
        aria-labelledby={`${
          isZipCodeConfirmed ? 'Change01' : 'Deliver to01'
        } dt${postalCode || locationPostalCode}`}
      />
    );

    return (
      <Tooltip
        role="dialog"
        title="Where should we deliver to?"
        anchor={<div>{anchor}</div>}
        open={open}
        handleTooltip={handleTooltip}
      >
        <div>
          <ZipForm submitAction={closeTooltip} />
        </div>
      </Tooltip>
    );
  },
);

ZipCodeTooltip.propTypes = {
  isShowInitialZipDialog: PropTypes.bool,
  hideInitialZipDialog: PropTypes.func,
  ssrReqRoute: PropTypes.object,
};

ZipCodeTooltip.defaultProps = {
  isPostalCodeDetermined: false,
  ssrReqRoute: {},
};

const mapStateToProps = createStructuredSelector({
  isShowInitialZipDialog: selectShowInitialZipDialog(),
  promotionLandingPageData: selectPromotionLandingPageData(),
  isZipCodeConfirmed: selectZipCodeConfirmed(),
  ssrReqRoute: selectSsrReqRoute(),
  postalCode: selectPostalCode(),
  locationPostalCode: selectZipCode(),
});

const mapDispatchToProps = {
  hideInitialZipDialog: hideInitialZipDialogAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ZipCodeTooltip),
);
