import React from 'react';
import PropTypes from 'prop-types';
import { Collapse as MUICollapse, ButtonBase } from '@material-ui/core';
import { Desktop } from 'components/Media';
import { isIE } from 'react-device-detect';

import styles from './styles.scss';

const Collapse = ({ content, open, onClose, isHomepage }) => (
  <div style={isIE && open && isHomepage ? { minHeight: '232px' } : {}}>
    <MUICollapse in={open} className={styles.drawer}>
      {content}
      <Desktop>
        <ButtonBase
          className={styles.closeIcon}
          onClick={onClose}
          aria-label="Close"
        />
      </Desktop>
    </MUICollapse>
  </div>
);

Collapse.propTypes = {
  content: PropTypes.any,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isHomepage: PropTypes.bool.isRequired,
};

export default Collapse;
