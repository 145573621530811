import React, { useEffect, useState, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import { withOrderUtils } from 'utils/withOrderUtils';
import { isSelfServeDomain } from 'utils/domainHelper';
import { Grid } from '@material-ui/core';
import NotFoundPage from 'containers/Landing/NotFoundPage/Loadable';
import { Mobile } from 'components/Media';
import ContentfulFields from 'components/ContentfulFields';
import Loader from 'components/Loader';
import Container from 'components/Ui/Container';
import Button from 'components/Ui/Button';
import OrderChangeDrawer from 'components/Dialogs/OrderChangeDrawer';
import EditingDeliveryOrderDrawer from 'components/Dialogs/EditingDeliveryOrderDrawer/Loadable';
import arrow from 'images/arrowBackward.png';
import ContentfulClient from 'utils/ContentfulClient';
import {
  fetchProductByItemNumber,
  fetchProductBySlug,
} from 'utils/contentfulUtils';
import { isPastDeliveryCutOffDateTime, isLoginRedirect } from 'utils/common';
import { createStructuredSelector } from 'reselect';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import {
  addToOrder,
  hideOrderChangeMessage as hideOrderChangeMessageAction,
} from 'containers/PrimoProducts/actions';
import { removeItem, changeItemQuantity } from 'containers/Cart/actions';
import {
  updateRecurringOrder,
  loadDeliveryOrder as loadDeliveryOrderRequest,
  removeHold as removeHoldRequest,
} from 'containers/PrimoAccount/actions';
import {
  selectIsHardLockout,
  selectIsEquipmentPickupLockout,
} from 'containers/PrimoProfile/selectors';
import {
  selectRecurringOrder,
  selectIsFirstDelivery,
  selectIsSelectedServiceLocationExcluded,
  selectNextDelivery,
  selectShowOrderChangeMessage,
  selectSelectedServiceLocation,
  selectShopDeliveryOrder,
  selectIsSelectedServiceIndefiniteHold,
} from 'containers/PrimoAccount/selectors';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import { isSsr } from 'utils/ssrHelper';
import { dataLayerPush } from 'utils/tracking';
// import ProcessingModal from '../ProcessingModal';
import { getCurrencyCode } from 'utils/translation';
import { FavoriteButton } from 'containers/PrimoProducts/Favorites';
import ProductImageGallery from './ProductImageGallery';
import ProductInfo from './ProductInfo';
import styles from './styles.scss';

import {
  selectProductByItemNumber,
  selectProductBySlugAndType,
} from '../selectors';

const PrimoProductPage = (props) => {
  const {
    isAuthenticated,
    isHardLockout,
    isFirstDelivery,
    isEquipmentPickupLockout,
    isSelectedServiceLocationExcluded,
    nextDelivery,
    recurringOrder,
    showOrderChangeMessage,
    hideOrderChangeMessage,
    serviceLocation,
    productIsFetching,
    loadDeliveryOrder,
    displayIndex,
    addToDeliveryOrder,
    isIndefiniteHold,
    removeHold,
    ssrReqRoute,
  } = props;
  const [isFetchInitiated, setIsFetchInitiated] = useState(false);
  const paramSlug = get(props, 'match.params.slug');
  const paramProdType = get(props, 'match.params.productType');
  const paramItemNum = get(props, 'match.params.itemNumber');
  const isRedirect = isLoginRedirect();
  const product = paramItemNum
    ? useSelector(selectProductByItemNumber(paramItemNum))
    : useSelector(selectProductBySlugAndType(paramProdType, paramSlug)) || null;
  const shopDeliveryOrder = useSelector(selectShopDeliveryOrder());
  const isServersideRendering = isSsr();
  const {
    site: {
      components: { page },
      loading: contentIsLoading,
    },
  } = props;
  const isServerRendered = ssrReqRoute && ssrReqRoute.key === 'product-page';

  useEffect(() => {
    async function loadProduct() {
      hideOrderChangeMessage();
      if (!product) {
        const contentfulClient = new ContentfulClient();
        const contentfulProduct = isAuthenticated
          ? await fetchProductByItemNumber(props.match.params.itemNumber, contentfulClient)
          : await fetchProductBySlug(props.match.params.slug, contentfulClient);
        const itemNumber = get(contentfulProduct, 'items.[0].fields.itemNumber');
        props.loadMerchandizedProducts({ itemNumber });
      }
    }
    loadProduct();
    setIsFetchInitiated(true);
  }, []);

  useEffect(() => {
    if (isFetchInitiated && !productIsFetching && !product) {
      displayProductList();
    }
  }, [productIsFetching, product]);

  const displayProductList = () => {
    props.history.push('/account/products/list');
  };

  const getDataLayer = (event, section) => {
    const { itemNumber, name, brand, category, price, size } = product;

    return {
      event,
      section,
      id: itemNumber,
      name,
      brand: brand || '',
      category,
      variant: size,
      price,
      quantity: 1,
      position: 1,
    };
  };

  const handleOrderChangeConfirm = () => {
    hideOrderChangeMessage();
    props.history.push('/account/next-delivery');
  };

  const handleOrderChangeCancel = () => {
    hideOrderChangeMessage();
  };

  const handleDeliveryOrderConfirm = () => {
    if (localStorage.getItem('profilePage') === 'true') {
      props.history.push('/account/profile');
      loadDeliveryOrder(shopDeliveryOrder);
    } else if (localStorage.getItem('nextDeliveryPage') === 'true') {
      props.history.push('/account/next-delivery');
      loadDeliveryOrder(shopDeliveryOrder);
    } else {
      props.history.push('/account/future-orders');
      loadDeliveryOrder(shopDeliveryOrder);
    }
  };

  const pushDataLaterForAddToDeliveryOrder = (quantity) => {
    const { itemNumber, name, category, price, brand, size } = product;
    dataLayerPush(
      'SelfServe',
      {
        event: 'add-to-order',
        section: 'future order product detail',
        id: itemNumber,
        name,
        brand: brand || '',
        category,
        variant: size,
        price,
        position: 1,
        quantity,
      },
      'dlS35',
    );
  };

  const handleUpdateDeliveryOrder = (quantity) => {
    if (!isIndefiniteHold && shopDeliveryOrder.holdId) {
      removeHold({
        deliveryOrder: shopDeliveryOrder,
        successCallback: () => {
          shopDeliveryOrder.skipDelivery = false;
          addToDeliveryOrder(shopDeliveryOrder, product, quantity);
          pushDataLaterForAddToDeliveryOrder(quantity);
        },
      });
    } else {
      addToDeliveryOrder(shopDeliveryOrder, product, quantity);
      pushDataLaterForAddToDeliveryOrder(quantity);
    }
  };

  const images = get(product, 'images', null);
  let backText = 'Back';
  if (product && product.categoryFilter) {
    backText = `${backText} to ${product.categoryFilter.name}`;
  }

  const isPastCutOff = isPastDeliveryCutOffDateTime(serviceLocation);
  const isReadonly =
    isFirstDelivery ||
    isSelectedServiceLocationExcluded ||
    isEquipmentPickupLockout ||
    isHardLockout;
  const nextDeliveryItems = get(nextDelivery, 'items', []);
  const noNextDelivery = nextDeliveryItems.length === 0;

  const recurringOrderItems = recurringOrder || [];
  let inRecurringOrder = false;
  let inNextDelivery = false;

  if (product && product.itemNumber) {
    inRecurringOrder =
      recurringOrderItems.find(
        (item) => item.itemNumber === product.itemNumber,
      ) !== undefined;
    inNextDelivery =
      nextDeliveryItems.find(
        (item) => item.itemNumber === product.itemNumber,
      ) !== undefined;
  }

  const isSelfServe = isSelfServeDomain();
  const {
    site: {
      ssrReqRoute: { protocol, hostname, originalUrl },
    },
  } = props;

  const url = isServersideRendering
    ? `${protocol}://${hostname}${originalUrl}`
    : window.location.href;

  return (
    <Loader loading={contentIsLoading || props.productIsFetching}>
      {!props.productIsFetching && !product ? (
        <NotFoundPage />
      ) : (
        <Fragment>
          <Helmet>
            <script type="application/ld+json">{`
                {
                  "@context": "https://schema.org/",
                  "@type": "Product",
                  "name": "${product && product.name}",
                  "image": [
                    "https:${product && product.image && product.image.url}"
                  ],
                  "description": "${product && product.longDescription}",
                  "sku": "${product && product.itemNumber}",
                  "brand": { "@type": "Brand", "name": "${product && product.brand
              }" },
                  "offers": {
                    "@type": "Offer",
                    "url": "${url}",
                    "priceCurrency": "${getCurrencyCode()}",
                    "price": "${product && product.price}",
                    "itemCondition": "https://schema.org/NewCondition",
                    "availability": "https://schema.org/InStock"
                  }
                }
        `}</script>
          </Helmet>
          <div className={styles.productWrapper}>
            <Container value="Product Page" className={styles.productPage}>
              <Button
                className={styles.backLink}
                icon={<img src={arrow} alt="" />}
                link
                text={backText}
                tracking={GTM_TRACKING_TAGS['Back to Category']}
                onClick={
                  isRedirect || isServerRendered
                    ? displayProductList
                    : props.history.goBack
                }
              />
              <div className={styles.productInfoWrapper}>
                <Grid
                  className={styles.productInfo}
                  container
                  spacing={4}
                  justify="space-between"
                >
                  <Mobile>
                    {product && isAuthenticated && (
                      <FavoriteButton
                        className={styles.favBtn}
                        item={{
                          itemId: product.id,
                          itemNumber: product.itemNumber,
                        }}
                        dataLayer={getDataLayer(
                          'add-item-to-favorites',
                          'product detail',
                        )}
                      />
                    )}
                    <h3 className={styles.title}>{product && product.name}</h3>
                  </Mobile>
                  <Grid
                    className={styles.imageGallery}
                    item
                    xs={12}
                    sm={12}
                    md={5}
                  >
                    {images && <ProductImageGallery images={images} />}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    {product && (
                      <ProductInfo
                        product={product}
                        displayIndex={displayIndex}
                        addToOrder={props.addToOrder}
                        removeItem={props.removeItem}
                        changeItemQuantity={props.changeItemQuantity}
                        recurringOrder={recurringOrder}
                        updateRecurringOrder={props.handleUpdateRecurringOrder}
                        isAuthenticated={isAuthenticated}
                        isReadonly={isReadonly}
                        isPastCutOff={isPastCutOff}
                        noNextDelivery={noNextDelivery}
                        inNextDelivery={inNextDelivery}
                        inRecurringOrder={inRecurringOrder}
                        serviceRecurringDeliveryId={
                          serviceLocation && serviceLocation.recurringDeliveryId
                        }
                        onUpdateDeliveryOrder={handleUpdateDeliveryOrder}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
            </Container>
            {!isSelfServe &&
              page &&
              page.contentModules.map((banner) => (
                <ContentfulFields
                  type={get(banner, 'sys.contentType.sys.id')}
                  document={banner}
                  key={banner.sys.id}
                />
              ))}
          </div>
          {!isServersideRendering && (
            <OrderChangeDrawer
              open={showOrderChangeMessage}
              onConfirm={handleOrderChangeConfirm}
              onCancel={handleOrderChangeCancel}
            />
          )}
          {shopDeliveryOrder && (
            <EditingDeliveryOrderDrawer
              section="future delivery product detail"
              shopDeliveryOrder={shopDeliveryOrder}
              onConfirm={handleDeliveryOrderConfirm}
            />
          )}
          {/* <ProcessingModal /> */}
        </Fragment>
      )}
    </Loader>
  );
};

PrimoProductPage.propTypes = {
  loadMerchandizedProducts: PropTypes.func.isRequired,
  productIsFetching: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  site: PropTypes.object.isRequired,
  addToOrder: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  changeItemQuantity: PropTypes.func.isRequired,
  handleUpdateRecurringOrder: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  isHardLockout: PropTypes.bool.isRequired,
  isFirstDelivery: PropTypes.bool.isRequired,
  isEquipmentPickupLockout: PropTypes.bool.isRequired,
  isSelectedServiceLocationExcluded: PropTypes.bool.isRequired,
  nextDelivery: PropTypes.object,
  recurringOrder: PropTypes.array,
  showOrderChangeMessage: PropTypes.bool.isRequired,
  hideOrderChangeMessage: PropTypes.func.isRequired,
  serviceLocation: PropTypes.object,
  loadDeliveryOrder: PropTypes.func.isRequired,
  isIndefiniteHold: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  recurringOrder: selectRecurringOrder(),
  isHardLockout: selectIsHardLockout(),
  isFirstDelivery: selectIsFirstDelivery(),
  isEquipmentPickupLockout: selectIsEquipmentPickupLockout(),
  isSelectedServiceLocationExcluded: selectIsSelectedServiceLocationExcluded(),
  nextDelivery: selectNextDelivery(),
  showOrderChangeMessage: selectShowOrderChangeMessage(),
  serviceLocation: selectSelectedServiceLocation(),
  isIndefiniteHold: selectIsSelectedServiceIndefiniteHold(),
});

const mapDispatchToProps = {
  addToOrder,
  removeItem,
  changeItemQuantity,
  handleUpdateRecurringOrder: updateRecurringOrder,
  hideOrderChangeMessage: hideOrderChangeMessageAction,
  loadDeliveryOrder: loadDeliveryOrderRequest,
  removeHold: removeHoldRequest,
};

PrimoProductPage.defaultProps = {};

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(PrimoProductPage);
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withOrderUtils,
)(PrimoProductPage);
