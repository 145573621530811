import { camelCase, get, take, find, forEach, isEmpty } from 'lodash';
import {
  takeEvery,
  call,
  put,
  all,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ContentfulClient from 'utils/ContentfulClient';
import { adjustPostalCode } from 'utils/common';
import {
  getDomainByHost,
  getRegionalBrandName,
  isSelfServeDomain,
  isAcquisitionDomain,
} from 'utils/domainHelper';
import {
  fetchPage as contentfulFetchPage,
  fetchNewFeatureVideo,
  fetchEntry,
  getPageSlugKey,
  fetchProductBySlug,
  fetchJSONData,
} from 'utils/contentfulUtils';
import { addPromotion, createCartSuccess } from 'containers/Cart/actions';
import { loadAvailableProducts } from 'containers/PrimoProducts/actions';
import {
  updateUserRegion,
  setUserRegionToBrandDefault,
} from 'containers/UserRegion/actions';
import {
  selectIsProductPage,
  selectSection,
  selectSsrReqRoute,
  selectLandingData,
  selectPromotionLandingPageData,
  selectCostcoAccount,
  selectForceFetch,
  selectAcquisitionVariables,
} from 'containers/Landing/selectors';
import { selectProductCategories } from 'containers/PrimoProducts/selectors';
import {
  selectPostalCode,
  selectBranch,
  selectBrand,
} from 'containers/UserRegion/selectors';
import { selectCartItems } from 'containers/Cart/selectors';

import { apiCall } from 'utils/swaggerClient';
import {
  setSection,
  setLayouts,
  getEntrySuccess,
  getEntryFailure,
  updateVariables,
  showBrandSwitchDialog,
  fetchPromotionLandingDataSuccess,
  fetchPromotionLandingDataFailure,
  setForceFetch,
  setSsrReqStatusCode,
} from 'containers/Landing/actions';
import { isSsr } from 'utils/ssrHelper';
import { getToUrl } from 'utils/translation';
import {
  loadAvailableProducts as fetchAvailableProducts,
  loadProductCategories,
  loadMerchandizedProducts,
} from 'containers/PrimoProducts/saga';
import {
  getCategoryId,
  getLoadProductsParams,
} from 'containers/PrimoProducts/helpers';

import * as types from './constants';
import {
  fetchPageSuccess,
  fetchPageFailure,
  getFeatureTourFailure,
  getFeatureTourSuccess,
} from './actions';
import { CLEAR_CART_FLAG } from '../Cart/constants';
import {
  updateLocalStorageCartId,
  updateLocalStorageCartSecret,
} from '../Cart/utils';

const contentfulClient = new ContentfulClient();

export function* fetchPage({ payload }) {
  const isServersideRendering = isSsr();
  try {
    const { key } = payload;
    const domain = getDomainByHost(9);
    const section = yield select(selectSection());
    const landingData = yield select(selectLandingData());
    const currentPageSlug = get(landingData, 'components.page.slug');
    const requestedSlug = getPageSlugKey(domain.page[key], section);
    const forceFetchFlag = yield select(selectForceFetch());
    if (
      currentPageSlug &&
      currentPageSlug === requestedSlug &&
      !forceFetchFlag
    ) {
      yield put({ type: types.SET_LOADER_FALSE });
      return;
    }

    const page = yield call(
      contentfulFetchPage,
      domain,
      contentfulClient,
      key || 'blank',
      section,
    );

    if (!page || isEmpty(page.items)) {
      yield put({ type: types.SET_LOADER_FALSE });
      throw new Error(`Requested page "${requestedSlug}" has no content.`);
    }

    const { slug, layouts } = page.items[0].fields;

    // TODO: Why not acquisition-office and acquisition-home
    if (slug === 'home') {
      yield put(setLayouts(layouts));
    }

    const ssrReqRoute = yield select(selectSsrReqRoute());
    const acquisitionVariables = yield select(selectAcquisitionVariables());
    yield put(
      fetchPageSuccess({ key, page, acquisitionVariables, ssrReqRoute }),
    );
    const isProductPage = yield select(selectIsProductPage());

    if (isProductPage && !isServersideRendering) {
      yield put(loadAvailableProducts());
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchPageFailure(error));
  }
}

function* fetchHome() {
  yield put(setForceFetch(true));
  yield call(fetchPage, { payload: { key: 'home' } });
}

function* navigateLandingPageLink({ payload: { link } }) {
  const section = yield select(selectSection());
  yield put(setSection(section));
  yield put(push(link));
}

function* navigateZipRedirect({
  payload: { zip, stay, stayCallback, clearCart = true },
}) {
  const selectedPostalCode = yield select(selectPostalCode());
  const postalCode = zip || selectedPostalCode;
  try {
    const zipCode = adjustPostalCode(postalCode);

    const { obj } = yield apiCall({
      operationId: 'brands.getAcqServiceabilityByZip',
      parameters: {
        postalCode: zipCode,
      },
      fullResponse: true,
    });

    const {
      brand,
      organizationCode: branch,
      dsServiceable,
      partnerServiceable,
      btbMigrationStatus,
    } = obj;

    if (btbMigrationStatus === 'Migrated') {
      yield put({ type: types.SHOW_ZIP_MIGRATED_DIALOG });
      return;
    }

    if (brand) {
      const selectedBrand = yield select(selectBrand());

      yield put({ type: types.NAVIGATE_ZIP_REDIRECT_SUCCESS });

      const selectedBranch = yield select(selectBranch());
      const cartItems = yield select(selectCartItems());
      const theBrand =
        brand === 'alhambra'
          ? 'alhambrawater'
          : brand === 'deeprock'
          ? 'deeprockwater'
          : brand;
      const costcoAccount = yield select(selectCostcoAccount());

      if (theBrand === selectedBrand && branch !== selectedBranch) {
        if (clearCart && cartItems.length > 0) {
          yield put({ type: CLEAR_CART_FLAG });
        } else {
          yield put(setForceFetch(true));
        }
      }

      if (dsServiceable === 'Y') {
        if (theBrand === selectedBrand) {
          const confirmedUserRegion = {
            postalCode,
            branch,
            brand: theBrand,
          };
          if (isAcquisitionDomain()) {
            localStorage.setItem(
              'confirmedUserRegion',
              JSON.stringify(confirmedUserRegion),
            );
          }
          yield put(updateUserRegion(confirmedUserRegion));

          if (!stay) {
            yield put(addPromotion());
            yield put(push(getToUrl('/quick-shop/select-water')));
          }
          if (stayCallback) {
            stayCallback();
          }
        } else if (costcoAccount) {
          if (stayCallback) {
            stayCallback();
          }
        } else {
          if (stayCallback) {
            window.stayCallback = stayCallback;
          }
          window.redirectPostalCode = postalCode;
          window.redirectBrand = theBrand;
          yield put(showBrandSwitchDialog());
        }
        return;
      }
    }

    if (partnerServiceable === 'Y') {
      const partnerEmail = get(obj, 'partnerEmail', '');
      const phoneValueFormatter = (a) =>
        `${a.substr(0, 3)}-${a.substr(3, 3)}-${a.substr(6, 4)}`;
      const partnerEntries = [
        {
          name: '{partnerPrimaryPhone}',
          value:
            obj.partnerPrimaryPhone && !obj.partnerPrimaryPhone.includes('-')
              ? phoneValueFormatter(obj.partnerPrimaryPhone)
              : obj.partnerPrimaryPhone,
        },
        { name: '{partnerEmail}', value: partnerEmail },
        { name: '{partnerName}', value: obj.partnerName },
      ];
      yield put(updateVariables(partnerEntries));
      yield put(push(`/nonserve-partner?postalCode=${postalCode}`));
      return;
    }

    yield put(updateUserRegion({ postalCode }));
    yield put(push('/non-serviceable'));
  } catch (error) {
    yield put(updateUserRegion({ postalCode }));
    yield put(push('/non-serviceable'));
    yield put({ type: types.NAVIGATE_ZIP_REDIRECT_FAILURE, error });
  }
}

function* navigateCostcoRoute({ payload }) {
  const {
    partnerName = 'Costco',
    partnerServiceable,
    serviceStatus,
    costcoServiceable,
    dsServiceable,
    postalCode,
    memberNum,
    memberType,
    organizationId,
    campaignId,
    elementId,
    referralCode,
  } = payload;

  try {
    if (partnerServiceable === 'Y' && partnerName === 'ABSOPURE') {
      yield put(push('/costcowater-nonserv-absopure'));
    } else if (
      partnerServiceable === 'Y' &&
      partnerName === 'PREMIUM WATERS, INC.'
    ) {
      yield put(push('/costcowater-nonserv-premiumwater'));
    } else if (
      serviceStatus === 'Active' &&
      costcoServiceable === 'Y' &&
      dsServiceable === 'Y'
    ) {
      yield createCampaign(
        campaignId,
        elementId,
        postalCode,
        organizationId,
        partnerName,
        memberType,
        memberNum,
        referralCode,
      );
      yield put(push('/quick-shop/select-water'));
    } else {
      yield put(push('/costcowater-nonserv-restrictions'));
    }
  } catch (error) {
    // catch error here
  }
}

function* createCampaign(
  campaignId,
  elementId,
  postalCode,
  organizationId,
  partnerName,
  memberType,
  memberNum,
  referralCode,
) {
  try {
    if (campaignId && elementId) {
      const cartRequestBody = {
        items: [],
        postalCode,
        branchId: organizationId,
        partnerName,
        partnerType: memberType,
        partnerMemberId: memberNum,
        campaignId,
        elementId,
        referralCode,
      };

      const cart = yield call(apiCall, {
        operationId: 'carts.createAnonymousCart',
        parameters: {},
        options: { requestBody: cartRequestBody },
      });

      updateLocalStorageCartId(cart.id);
      updateLocalStorageCartSecret(cart.secretId);
      yield put(createCartSuccess(cart));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* fetchFeatureTour() {
  try {
    const newFeatureVideo = yield call(fetchNewFeatureVideo, contentfulClient);
    yield put(getFeatureTourSuccess(newFeatureVideo));
  } catch (error) {
    yield put(getFeatureTourFailure(error));
  }
}

const getVariables = (entry) => {
  const str = get(
    entry,
    'fields.content.content[0].content[0].value',
    '{}',
  ).replace(/\n/g, '');
  return JSON.parse(str);
};

export function* fetchVariables({ payload: { title, brandTitle, key } }) {
  try {
    const ssrReqRoute = yield select(selectSsrReqRoute());
    const brand = getRegionalBrandName(ssrReqRoute, 8);
    const entry = yield call(fetchEntry, contentfulClient, title);
    let vars = getVariables(entry);
    if (brandTitle) {
      const brandedEntry = yield call(fetchEntry, contentfulClient, brandTitle);
      const brandedVars = getVariables(brandedEntry);
      if (brandedVars[brand]) {
        // Make the branded variables override the other variables
        vars = [...brandedVars[brand], ...vars];
      }
    }
    if (typeof window !== 'undefined') {
      window.PrimoBrandName = brand;
      window.PrimoVariables = vars;
    }
    yield put(getEntrySuccess({ entry: vars, key }));
  } catch (error) {
    yield put(getEntryFailure(error));
  }
}

export function* fetchResourceBundles() {
  try {
    const isSS = isSelfServeDomain();
    const entryTitleUSEnglish = isSS
      ? 'SelfServe > US > English'
      : 'Acquisition > English';
    const entryTitleCanadaEnglish = isSS
      ? 'SelfServe > Canada > English'
      : 'Acquisition > Canada > English';
    const entryTitleCanadaFrench = isSS
      ? 'SelfServe > Canada > French'
      : 'Acquisition > French';

    const entryFrench = yield call(
      fetchJSONData,
      contentfulClient,
      entryTitleCanadaFrench,
    );
    const canadaFrenchResourceBundle = get(
      entryFrench,
      'items[0].fields.jsonData',
    );
    yield put(
      getEntrySuccess({
        entry: canadaFrenchResourceBundle,
        key: 'canadaFrenchResourceBundle',
      }),
    );

    const entryCanadaEnglish = yield call(
      fetchJSONData,
      contentfulClient,
      entryTitleCanadaEnglish,
    );
    const canadaEnglishResourceBundle = get(
      entryCanadaEnglish,
      'items[0].fields.jsonData',
    );
    yield put(
      getEntrySuccess({
        entry: canadaEnglishResourceBundle,
        key: 'canadaEnglishResourceBundle',
      }),
    );

    const entryEnglish = yield call(
      fetchJSONData,
      contentfulClient,
      entryTitleUSEnglish,
    );
    const usEnglishResourceBundle = get(
      entryEnglish,
      'items[0].fields.jsonData',
    );
    yield put(
      getEntrySuccess({
        entry: usEnglishResourceBundle,
        key: 'usEnglishResourceBundle',
      }),
    );

    if (typeof window !== 'undefined') {
      window.usEnglishResourceBundle = usEnglishResourceBundle;
      window.canadaEnglishResourceBundle = canadaEnglishResourceBundle;
      window.canadaFrenchResourceBundle = canadaFrenchResourceBundle;
    }
  } catch (error) {
    yield put(getEntryFailure(error));
  }
}

export function* fetchJSONDataByTitle({ payload: { title, key } }) {
  try {
    const res = yield call(fetchJSONData, contentfulClient, title);
    const json = get(res, 'items[0].fields.jsonData');
    const entry = json[key] ? json[key] : json;
    yield put(getEntrySuccess({ entry, key }));
  } catch (error) {
    yield put(getEntryFailure(error));
  }
}

export function* fetchEntryByTitle({ payload: { title, key } }) {
  try {
    const entry = yield call(fetchEntry, contentfulClient, title);
    yield put(getEntrySuccess({ entry, key }));
  } catch (error) {
    yield put(getEntryFailure(error));
  }
}

export function* fetchKeyEntry({ payload: { entryId, key } }) {
  try {
    const entry = yield call(fetchEntry, contentfulClient, entryId);
    yield put(getEntrySuccess({ entry, key }));
  } catch (error) {
    yield put(getEntryFailure(error));
  }
}

const setVariables = (variables, data) => {
  forEach(variables, (item) => {
    if (item.name === '{callToOrder}') {
      item.value = data['{callToOrder}'];
    }
    if (item.name === '{offerExpiresDate}') {
      item.value = data['{offerExpiresDate}'];
    }
  });
};

export function* fetchPromotionLandingData({ payload: { path } }) {
  const pathLowerCase = path.toLowerCase();
  try {
    const params = {
      contentType: 'promotionLandingPagePaths',
      query: { path: pathLowerCase },
    };
    const response = yield contentfulClient.getEntries(params);
    const promotionLandingPageData = get(response, 'items[0].fields');
    const promotionLandingPageKey =
      promotionLandingPageData && promotionLandingPageData.pageSlug;

    const thePromotionLandingPageDataVariables = get(
      promotionLandingPageData,
      'variables',
      [],
    );
    const acquisitionVariables = yield select(selectAcquisitionVariables());
    // Find the promotionLandingPageData in the acquisitionVariables matching the path
    const found = find(acquisitionVariables, (item) =>
      item.name.includes(pathLowerCase),
    );

    // If found, replace the promotionLandingPageData values and variables using the found
    if (found) {
      promotionLandingPageData.campaignId = found.campaignId;
      promotionLandingPageData.elementId = found.elementId;
      setVariables(thePromotionLandingPageDataVariables, found);
      setVariables(acquisitionVariables, found);
    }
    // HINT: Findout if someone wants to override via browser search params.
    applySearchParams(promotionLandingPageData);

    const vars = [
      ...acquisitionVariables,
      ...thePromotionLandingPageDataVariables,
    ];
    const key = 'acquisitionVariables';
    yield put(getEntrySuccess({ entry: vars, key }));

    if (!promotionLandingPageKey) {
      yield put(fetchPromotionLandingDataFailure());
      return;
    }

    yield put(fetchPromotionLandingDataSuccess(promotionLandingPageData));
  } catch (error) {
    yield put(fetchPromotionLandingDataFailure());
  }
}

function applySearchParams(target) {
  Object.assign(
    target,
    Object.fromEntries(
      new URLSearchParams(
        Array.from(
          new URLSearchParams(window.location.search),
          ([key, value]) => [camelCase(key), value],
        ),
      ),
    ),
  );
}

export function* ssrRootSaga(section) {
  try {
    const route = yield select(selectSsrReqRoute());
    yield put(setSection(section || types.SECTION_HOME));

    yield put(setUserRegionToBrandDefault());
    yield fetchResourceBundles();
    // For now Acquisition and Self Serve will use the same variables
    yield fetchVariables({
      payload: {
        title: '5Yb812r4dC1Oc19T3UXS8m', // Acquisition > Landing > Variables
        brandTitle: '5coHCEQ6FD1ECzrYSD44kE', // Acquisition > Branded > Variables
        key: 'acquisitionVariables',
      },
    });
    let key;

    if (route.key === 'promotion-landing-page') {
      const path = route.path.slice(1);
      yield fetchPromotionLandingData({ payload: { path } });
      yield take('*');
      const promotionLandingPageData = yield select(
        selectPromotionLandingPageData(),
      );
      const promotionLandingPageKey = get(promotionLandingPageData, 'pageSlug');
      key = promotionLandingPageKey || types.pages.pageNotFound;
    } else {
      key = route.fetchKey;
      yield loadProductCategories();
    }
    yield fetchPage({ payload: { key } });
    yield take('*');

    const isProductPage = yield select(selectIsProductPage());

    if (isProductPage) {
      yield fetchAvailableProducts();
      yield take('*');
    }
    if (route.key.includes('products')) {
      const { categoryId } = route.matchParams;
      const productCategories = yield select(selectProductCategories());

      // HINT: Product category not found? Send 404.
      if (!getCategoryId(categoryId, productCategories)) {
        yield put(setSsrReqStatusCode(404));
        return;
      }
      const loadProductsParams = getLoadProductsParams(1, productCategories, {
        categoryId,
      });
      yield loadMerchandizedProducts({ payload: { ...loadProductsParams } });
      yield take('*');
    }
    if (route.key === 'product-page') {
      const { slug } = route.matchParams;
      const contentfulProduct = yield fetchProductBySlug(
        slug,
        contentfulClient,
      );
      const itemNumber = get(contentfulProduct, 'items.[0].fields.itemNumber');
      if (!itemNumber) {
        yield put(setSsrReqStatusCode(404));
        return;
      }

      yield loadMerchandizedProducts({ payload: { itemNumber } });
      yield take('*');
    }
    if (route.key === 'quickshop') {
      const {
        matchParams: { page },
      } = route;

      const productCategories = yield select(selectProductCategories());
      const categoryName =
        page === 'select-equipment' ? 'Equipment' : 'Large Bottle';

      const category = productCategories.find(
        (cat) => cat.fields.name === categoryName,
      );
      const categoryId = get(category, 'sys.id');
      yield loadMerchandizedProducts({ payload: { categoryId } });
      yield take('*');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLeading(types.FETCH_HOME, fetchHome),
    takeLatest(types.FETCH_PAGE_REQUEST, fetchPage),
    takeEvery(types.NAVIGATE_ZIP_REDIRECT, navigateZipRedirect),
    takeEvery(types.NAVIGATE_LANDING_PAGE_LINK, navigateLandingPageLink),
    takeEvery(types.NAVIGATE_COSTCO_ROUTE, navigateCostcoRoute),
    takeLatest(types.FETCH_FEATURE_TOUR, fetchFeatureTour),
    takeLatest(types.FETCH_ENTRY_BY_TITLE, fetchEntryByTitle),
    takeLatest(types.FETCH_JSON_DATA, fetchJSONDataByTitle),
    takeLatest(types.FETCH_RESOURCE_BUNDLES, fetchResourceBundles),
    takeLatest(types.FETCH_ENTRY, fetchKeyEntry),
    takeLatest(types.FETCH_VARIABLES, fetchVariables),
    takeLatest(types.FETCH_PROMOTION_LANDING_DATA, fetchPromotionLandingData),
  ]);
}
