import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { applicationUrl, logoutURL, excludedRedirectURL } from 'utils/common';
import {
  getAuthClientId,
  getDomainByHost,
  isAcquisitionDomain,
  isSelfServeDomain,
} from 'utils/domainHelper';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isIE } from 'react-device-detect';
import {
  handleAuthentication,
  logout as handleLogout,
  logoutInitiated as handleLogoutInitiated,
  handleAuthenticationFailure,
} from 'containers/Authentication/actions';
import Loader from 'components/Loader';
import { selectIsExcluded } from 'containers/Authentication/selectors';
import { createStructuredSelector } from 'reselect';
import cookies from 'js-cookie';
import { isSsr } from 'utils/ssrHelper';
import { getFsUid } from 'utils/fullStory';
import { getServerData } from 'utils/getServerData';
import UnsupportedBrowserDialog from 'components/Dialogs/UnsupportedBrowserDialog/Loadable';

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
const AuthClient = ({
  children,
  handleAuth,
  logoutUser,
  handleAuthFailure,
  logoutInitiated,
  isExcluded,
}) => {
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(false);
  const [isAuthInitiated, setIsAuthInitiated] = useState(false);
  const [isAuth0InitRequired, setIsAuth0InitRequired] = useState(
    isSelfServeDomain(),
  );
  const [unsupportedBrowser, setUnsupportedBrowser] = useState(false);
  const APPLICATION_URL = applicationUrl();
  const isWindowDefined = !isSsr();

  useEffect(() => {
    if (isWindowDefined) {
      const initAuth0 = async () => {
        setLoading(true);
        const auth0FromHook = await createAuth0Client({
          domain: getServerData('AUTH_DOMAIN'),
          client_id: getAuthClientId(),
          redirect_uri: APPLICATION_URL,
          cacheLocation: 'localstorage',
          useRefreshTokens: true,
        });
        setAuth0(auth0FromHook);
        // ["unauthorized", "access_denied"]
        if (window.location.search.includes('error=unauthorized')) {
          const urlText = decodeURIComponent(window.location.href);
          const params = urlText.split('&');
          const errorDescription = params.find((val) =>
            val.includes('error_description'),
          );
          const errMessage = errorDescription;
          handleAuthFailure(errMessage);
          await auth0FromHook.logout({
            returnTo: logoutURL(),
          });
          return;
        }

        const { appState } =
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
            ? await auth0FromHook.handleRedirectCallback()
            : {};

        const isAuthenticated = await auth0FromHook.isAuthenticated();

        if (isAuthenticated) {
          const accessToken = await auth0FromHook.getTokenSilently();
          const signInUser = await auth0FromHook.getUser();
          handleAuth({ accessToken, signInUser, appState });
        } else {
          logoutUser();
        }
        setLoading(false);
        setIsAuthInitiated(true);
      };

      const isMobileAppView = cookies.get('isMobileView') === 'Y';
      const isPureflo =
        isWindowDefined &&
        (window.location.host.includes('pureflo') ||
          (process.env.NODE_ENV === 'development' &&
            window.location.host.includes('localhost') &&
            getServerData('LOCAL_DOMAIN') === 'pureflo'));
      if (
        isWindowDefined &&
        (window.isCostcoWater ||
          isPureflo ||
          isMobileAppView ||
          isAcquisitionDomain())
      ) {
        setIsAuth0InitRequired(false);
      } else {
        initAuth0();
      }
    }

    if (isIE) {
      setUnsupportedBrowser(true);
    }
  }, []);

  const logout = async () => {
    await logoutInitiated();
    const defaultRedirectURL = logoutURL();
    const excludedURL = excludedRedirectURL();
    const redirectURL = isExcluded ? excludedURL : defaultRedirectURL;
    await logoutUser();
    await auth0Client.logout({
      returnTo: redirectURL,
    });
  };

  const loginWithRedirect = async (params = {}) => {
    const {
      showForm: displayForm,
      firstName,
      lastName,
      accountNumber,
      emailId,
      userToken,
    } = params;
    const { slug, brandUrl } = getDomainByHost(1);
    await auth0Client.loginWithRedirect({
      subDomain: slug,
      showForm: displayForm,
      brandingUrl: brandUrl,
      baseURL: window.location.host,
      showFooterLinks: true,
      showHeader: true,
      showCreateUser: true,
      appState: params.redirectToPath || '',
      firstName,
      lastName,
      accountNumber,
      emailId,
      userToken,
      fsuid: getFsUid(),
    });
  };

  if (isWindowDefined && isAuth0InitRequired && (loading || !isAuthInitiated)) {
    return <Loader />;
  }

  return (
    <>
      {unsupportedBrowser ? (
        <UnsupportedBrowserDialog notLoggedIn logout />
      ) : (
        <Auth0Context.Provider
          value={{
            handleRedirectCallback: () => auth0Client.handleRedirectCallback(),
            getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
            getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
            getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
            loginWithRedirect,
            logout,
            loading,
          }}
        >
          {children}
        </Auth0Context.Provider>
      )}
    </>
  );
};

const mapDispatchToProps = {
  handleAuth: handleAuthentication,
  logoutUser: handleLogout,
  handleAuthFailure: handleAuthenticationFailure,
  logoutInitiated: handleLogoutInitiated,
};

const mapStateToProps = createStructuredSelector({
  isExcluded: selectIsExcluded(),
});

AuthClient.propTypes = {
  children: PropTypes.any,
  handleAuth: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  handleAuthFailure: PropTypes.func.isRequired,
  logoutInitiated: PropTypes.func.isRequired,
};

export const Auth0Provider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthClient);
