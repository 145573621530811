import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Checkbox as MUICheckbox,
  withStyles,
  FormControlLabel,
} from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { checkboxIcons } from 'images';
import { translateData } from 'utils/translation';

const styles = (theme) => ({
  checkbox: {
    border: theme.borders.greyBorder(),
    borderRadius: '30%',
    width: '50px',
    height: '50px',
    padding: '5px',
    color: 'transparent',
    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
      padding: '1px',
      borderWidth: '3px',
    },
  },
  checkboxActive: {
    backgroundImage: theme.gradients.primaryGradient,
    color: theme.colors.white,
  },
  rectCheckbox: {
    width: '40px',
    height: '40px',
  },
});

const Checkbox = ({
  id,
  ariaLabel,
  checked,
  classes,
  onChange,
  label,
  labelPlacement = 'end',
  disabled,
  locked,
  isRectangle,
  defaultChecked = false,
  ariaDescribedby,
  labelClassName,
}) => {
  const [isChecked, setChecked] = React.useState(checked || defaultChecked);

  React.useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const translatedAriaLabel = translateData(ariaLabel);
  const translatedLabel = translateData(label);

  const handleChange = ({ target: { checked: domChecked } }) => {
    setChecked(domChecked);
    onChange(domChecked);
  };

  const checkedIcon = isRectangle ? (
    <img
      src={checkboxIcons.CheckboxOn}
      alt=""
      className={classes.rectCheckbox}
    />
  ) : (
    <Done
      classes={{ root: classNames(classes.checkbox, classes.checkboxActive) }}
    />
  );

  const uncheckedIcon = isRectangle ? (
    <img
      src={checkboxIcons.CheckboxOff}
      alt="checkboxOff"
      className={classes.rectCheckbox}
    />
  ) : (
    <Done color="action" classes={{ root: classes.checkbox }} />
  );

  const CheckboxComponent = (
    <MUICheckbox
      id={id}
      icon={uncheckedIcon}
      color="primary"
      inputProps={{
        'aria-label': translatedAriaLabel || translatedLabel,
        'aria-describedby': ariaDescribedby || '',
      }}
      checkedIcon={
        locked ? (
          <img
            src={checkboxIcons.CheckboxLocked}
            alt="checkboxLocked"
            className={classes.rectCheckbox}
          />
        ) : (
          checkedIcon
        )
      }
      onChange={handleChange}
      checked={isChecked}
      disabled={disabled || locked}
    />
  );

  return translatedLabel ? (
    <FormControlLabel
      control={CheckboxComponent}
      label={translatedLabel}
      data-tracking={translatedLabel}
      labelPlacement={labelPlacement}
      className={labelClassName}
    />
  ) : (
    CheckboxComponent
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelPlacement: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  isRectangle: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  ariaLabel: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  id: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(Checkbox);
