import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import closeIcon from 'images/close.svg';
import burger from 'images/burger.svg';
import HtmlData from 'components/Ui/HtmlData/index';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import { translateData } from 'utils/translation';
import MenuContent from './MenuContent';
import styles from './styles.scss';

const MOBILE_MENU_KEY = 'mobileMenu';

class MobileMenu extends PureComponent {
  state = {
    open: false,
  };

  handleClick = () => {
    if (!this.state.open) {
      this.openCollapse();
    } else {
      this.closeCollapse();
    }
  };

  openCollapse = () => {
    document.body.style.overflowY = 'hidden';
    this.setState({
      open: true,
    });

    this.props.openCollapse(
      MOBILE_MENU_KEY,
      <MenuContent
        fields={this.props.fields}
        open={this.state.open}
        openCollapse={this.props.openCollapse}
        closeCollapse={this.closeCollapse}
        loginWithRedirect={this.props.loginWithRedirect}
        logoutWithRedirect={this.props.logout}
      />,
    );
  };

  closeCollapse = () => {
    document.body.style.overflowY = 'scroll';
    this.setState({
      open: false,
    });

    this.props.closeCollapse();
  };

  render() {
    const { open } = this.state;

    return (
      <HtmlData tracking={GTM_TRACKING_TAGS['Main Menu']}>
        <ButtonBase
          className={styles.menuButton}
          onClick={this.handleClick}
          aria-label={translateData('Close menu')}
          aria-haspopup="true"
          aria-expanded={open}
        >
          <img src={open ? closeIcon : burger} alt="" />
        </ButtonBase>
      </HtmlData>
    );
  }
}

MobileMenu.propTypes = {
  openCollapse: PropTypes.func.isRequired,
  closeCollapse: PropTypes.func.isRequired,
  loginWithRedirect: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
};

export default MobileMenu;
