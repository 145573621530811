import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCampaignId } from 'containers/Cart/selectors';
import { Button, Checkbox } from 'components/Ui';

import styles from './Payment/styles.scss';

const LABELS = [
  'Are you the customer filling out the survey today?',
  'Are you the primary decision maker for the delivery address?',
  'Did the Sales Representative explain the process for the initial delivery and how the service works?',
  'Did the Sales Representative review the wider variety of products we offer, beyond 5-gallon bottles?',
  'Did the Sales Representative review the Delivery Fee?',
  'Did the Sales Representative explain bottle deposits?',
  'Did you provide us with the correct email address?',
];

const SMART_CIRCLE_CAMPAIGN_IDS = ['348', '350', '351', '352'];

const isSmartCircleCampaign = (campaignId) =>
  SMART_CIRCLE_CAMPAIGN_IDS.includes(campaignId);

const ChecklistSmartCircle = ({ onAllChecked }) => {
  const campaignId = useSelector(selectCampaignId());
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [listValues, setListValues] = useState(
    Array(LABELS.length).fill(false),
  );

  useEffect(() => {
    onAllChecked(isAllChecked);
  }, [isAllChecked, onAllChecked]);

  useEffect(() => {
    const isCostco = window.isCostcoWater;
    const isSmartCircle = isSmartCircleCampaign(campaignId);

    if (!isCostco || !isSmartCircle) {
      setIsAllChecked(true);
    }
  }, [campaignId]);

  const isCostco = window.isCostcoWater;
  const isSmartCircle = isSmartCircleCampaign(campaignId);

  if (!isCostco || !isSmartCircle) return null;

  const handleCheckbox = (index) => {
    const newListValues = [...listValues];
    newListValues[index] = !newListValues[index];
    setListValues(newListValues);
    setIsAllChecked(newListValues.every((value) => value));
  };

  const checkAll = () => {
    const newListValues = listValues.fill(true);
    setListValues(newListValues);
    setIsAllChecked(true);
  };

  return (
    <div className={styles.block}>
      {LABELS.map((label, index) => (
        <div className={styles.autoPay} key={index}>
          <Checkbox
            isRectangle
            className={styles.checkbox}
            label={label}
            id={`checkbox-${index}`}
            checked={listValues[index]}
            onChange={() => handleCheckbox(index)}
            ariaLabel={label}
            ariaDescribedby={`checkbox-${index}-description`}
            required
          />
        </div>
      ))}

      <Button
        type="button"
        text="Select All"
        onClick={checkAll}
        className={styles.btnSelectAll}
        bold
        capitalize
      />
    </div>
  );
};

ChecklistSmartCircle.defaultProps = {
  onAllChecked: () => false,
};

export default memo(ChecklistSmartCircle);
