import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'url-search-params-polyfill';
// Needed for redux-saga es6 generator support
import '@babel/polyfill/noConflict';

// Import all the third party stuff
import { ConnectedRouter } from 'connected-react-router/immutable';
import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import 'sanitize.css/sanitize.css';
import 'aos/dist/aos.css';
import { ThemeProvider } from '@material-ui/styles';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import history from 'utils/history';
import 'focus-visible/dist/focus-visible';

// Load the favicon
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./app/images/favicon.ico';
/* eslint-enable import/no-unresolved, import/extensions */

import './app/utils/formValidators';
import WaterApp from './app/main';
import theme from './app/theme/theme';

import store from './app/utils/store';

library.add(fas);

Loadable.preloadReady().then(() => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <WaterApp location={history.location} />
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('app'),
    () => {
      const jssStyles = document.getElementById('jss-server-side');
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles);
      }
    },
  );
});

if (module.hot) {
  module.hot.accept();
}
